import React, { useRef } from 'react';
import HTMLFlipBook from "react-pageflip";
import * as Img from '../../components/Img';

import brochureImg1 from "../../img/brochure/brochure-thumbs/Untitled.webp";
import brochureImg2 from "../../img/brochure/brochure-thumbs/Untitled2.webp";
import brochureImg3 from "../../img/brochure/brochure-thumbs/Untitled3.webp";
import brochureImg4 from "../../img/brochure/brochure-thumbs/Untitled4.webp";
import brochureImg5 from "../../img/brochure/brochure-thumbs/Untitled5.webp";

export default function FlipBook() {
  const flipBookRef = useRef(null);

  const nextButtonClick = () => {
    if (flipBookRef.current && flipBookRef.current.pageFlip) {
      flipBookRef.current.pageFlip().flipNext();
    }
  };

  const prevButtonClick = () => {
    if (flipBookRef.current && flipBookRef.current.pageFlip) {
      flipBookRef.current.pageFlip().flipPrev();
    }
  };

  return (
    <>
      <HTMLFlipBook 
        width={415} 
        height={587}
        minWidth={415}
        minHeight={587}
        maxWidth={1000}
        maxHeight={1533}
        maxShadowOpacity={0.5}
        ref={flipBookRef}
      >
        <div className="flipbook-item"><img src={brochureImg1} alt=""/></div>
        <div className="flipbook-item"><img src={brochureImg2} alt=""/></div>
        <div className="flipbook-item"><img src={brochureImg3} alt=""/></div>
        <div className="flipbook-item"><img src={brochureImg4} alt=""/></div>
        <div className="flipbook-item"><img src={brochureImg5} alt=""/></div>
      </HTMLFlipBook>
    
      <div className="fbb-items fbb-prev" onClick={prevButtonClick}><img src={Img.arrowleft} alt="" /></div>
      <div className="fbb-items fbb-next" onClick={nextButtonClick}><img src={Img.arrowright} alt="" /></div>
   
    </>
  );
}
