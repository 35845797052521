import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

export default function DropdownMenu({ title, links, cdiclassName=null }) {

  // ---
  const [isDropActive, setIsDropActive] = useState(false);

  const handleMouseEnter = () => {
    setIsDropActive(true);
  };

  const handleMouseLeave = () => {
    setIsDropActive(false);
  };

  const handleItemClick = () => {
    setIsDropActive(false); // Remove active class when an item is clicked
  };
  // ---

  return (
    <div className={`cmb-drop-item ${isDropActive ? 'active' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className="cmb-item">
        {title}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
        </svg>
        <div className="cmb-item-line"></div>
      </div>
      <div className={`cmb-drop-bx ${isDropActive ? 'active' : ''}`}>
        <div className="cmb-drop-inner">
          <div className={`cdi-main ${cdiclassName}`}>
            {links.map((link, index) => (
              <NavLink
                key={index}
                to={link.to}
                className="cdi-main-item"
                onClick={handleItemClick}
              >
                {link.text} <img src={link.imgSrc} alt="" />
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
