import React from 'react';

// Slider Code
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';


// Images Common File
import * as Img from '../../components/Img';

/// Images Common File
import grap from '../../img/icons/grap.svg';

import trade9 from '../../img/trade/trade(9).svg'
import trade11 from '../../img/trade/trade(11).svg'
import trade12 from '../../img/trade/trade(12).svg'

export default function CommoditiesSlider() {
  const commoditiesslider = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    swipeToSlide:true,
    className: "slidersidebox center",
    centerMode: true,
    speed: 500,
    autoplaySpeed: 3000,
    centerPadding: "28%",
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          centerPadding: "25%",
        }
      },
      {
        breakpoint: 1200,
        settings: {
          centerPadding: "12%",
        }
      },
      {
        breakpoint: 992,
        settings: {
          centerPadding: "6%",
        }
      }
    ]
  };
  return (
    <>
      <div data-aos="fade-up" className='arrowfullwidth'>
        <Slider className={`slick-hover mt-4`} {...commoditiesslider}>

          <div className="forexslider-card-outer">
            <div className="forexslider-card-items">
              <div className="fci-right">
                <img src={grap} alt="" />
              </div>
              <div className="fci-left">

                  <div className="fci-img-bx">
                    <img src={trade12} alt="" />
                    {/* <img src={flag2} alt="" /> */}
                  </div>
                  
                  <div className="fci-country-name">
                  Gold<span>Futures</span>
                  </div>

                  <div className="fci-rates">
                    <div className="fci-rate">1.0932<span>USD</span></div>
                    <div className="fci-status fci-red">−0.00072 −0.07%</div>
                  </div>

                  <div className="fci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

              </div>
            </div>
          </div>

          <div className="forexslider-card-outer">
            <div className="forexslider-card-items">
              <div className="fci-right">
                <img src={grap} alt="" />
              </div>
              <div className="fci-left">

                  <div className="fci-img-bx">
                    <img src={trade11} alt="" />
                    {/* <img src={flag2} alt="" /> */}
                  </div>
                  
                  <div className="fci-country-name">
                  COCOA FUTURES<span>COCOA</span>
                  </div>

                  <div className="fci-rates">
                    <div className="fci-rate">1.0932<span>USD</span></div>
                    <div className="fci-status fci-green">+0.00072 +0.07%</div>
                  </div>

                  <div className="fci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

              </div>
            </div>
          </div>

          <div className="forexslider-card-outer">
            <div className="forexslider-card-items">
              <div className="fci-right">
                <img src={grap} alt="" />
              </div>
              <div className="fci-left">

                  <div className="fci-img-bx">
                    <img src={trade9} alt="" />
                    {/* <img src={flag2} alt="" /> */}
                  </div>

                  <div className="fci-country-name">
                  CFDs on Silver<span>Silver</span>
                  </div>

                  <div className="fci-rates">
                    <div className="fci-rate">1.0932<span>USD</span></div>
                    <div className="fci-status fci-green">+0.00072 +0.07%</div>
                  </div>

                  <div className="fci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

              </div>
            </div>
          </div>

        </Slider>
      </div>
    </>
  );
}
