import React from 'react';
import {Link} from "react-router-dom";
import CommonHelmet from '../../components/CommonHelmet';

// Design Files
// import { useMenu } from '../../components/Header/MenuContext'
import Forexslider from '../Sliders/Forexslider';

// Images Common File
import * as Img from '../../components/Img';


export default function PageForex() {

  // const { handleScreenChange } = useMenu();

  const questions = [
    {
      question: "DISCOVER HOW TO TRADE",
      answer: (
        <>
          <p>Download our Education app to learn how to trade on WinproFX Trader's various financial instruments. To get started, we've covered every aspect of trading.</p>

          <ul>
            <li>A Brief Summary of Recent News and Opinions</li>
            <li>Volatility Forecast Based on Technical Analysis</li>
            <li>Brief overview of major currency pairs based on the provided fundamental</li>
          </ul>

          <p>For example, if you choose to trade GBP/USD (British pound/US dollar) and you think the value of the GBP will rise against USD, you go long (buy). If you think GBP will fall against USD, you go short (sell). If your prediction is correct, you make a profit. If your prediction is incorrect, you would make a loss. Remember, losses can exceed deposits..</p>
        </>
      ),
    },
    {
      question: "WHAT IS FOREX?",
      answer: (
        <>
          <p>Forex stands for FOREIGN EXCHANGE. In simple terms, by sitting in one place in the world, one can trade in any Country’s Currency, Metals, World Indices and Crypto by studying world economy and technical charts.</p>
        </>
      ),
    },
    {
      question: "HOW TO TRADE FOREX?",
      answer: (
        <>
          <p>First, find a reputed Forex broker, such as WinproFX, open an account, deposit funds, analyse the market trend, choose your preferred trading instrument, and finally take your position to earn a profit.</p>
        </>
      ),
    }
  ];
  return (
    <>
      <CommonHelmet 
        title="Best Forex Trading Platform for Beginners & Experts, Dynamic & secure trading"
        description="Explore the dynamic world of Forex trading, world largest financial market with Forex, 24/5 and trading over 5 trillion daily Start your forex journey today."
        keywords="Forex Trading Platform, Best Forex Trading Platform, Forex Market Solutions, Forex Trading Services, Forex Trading Tools, Online Forex Trading, Forex Trading Strategies, Forex Trading for Beginners, Advanced Forex Trading, WinProFX Forex Services"
      />
      <div className="d-none" style={{display:"none"}}>
        <h1>Best Forex Trading Platform for Beginners & Experts, Dynamic & secure trading</h1>
        <h2>Explore the dynamic world of Forex trading, world largest financial market with Forex, 24/5 and trading over 5 trillion daily Start your forex journey today.</h2>
        <b>Forex</b>
        <b>How to Trade</b>
        <b>Trade Forex</b>
        <b>Trading</b>
        <img src="" alt="Forex Trading Platform, Best Forex Trading Platform, Forex Market Solutions, Forex Trading Services, Forex Trading Tools, Online Forex Trading, Forex Trading Strategies, Forex Trading for Beginners, Advanced Forex Trading, WinProFX Forex Services, DOGEUSD, EURUSD" />
      </div>

      <section className="relative maxwidth1920">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx">
            <div className="breadcrumb-bx mt-5 mt-sm-0">
                <Link className="breadcrumb-link" to="/">Home</Link>
                <div className="breadcrumb-link breadcrumb-active">/ Markets / Forex</div>
            </div>
            <div data-aos="fade-up" className="tlm-heading">WHAT IS <span className="tlm-h-color">FOREX</span> <br/> TRADING?</div>
            <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">Forex or the Foreign Exchange is a global market where currencies trade. It vastly overshadows the stock market, with forex trading reaching up to 5 trillion USD a day. Since the forex market is not centralized it is open 24 hours a day, from Sunday night to Friday night. Choosing the Best Forex Trading Platform is crucial for optimizing your trading experience.</div>
            <div data-aos="fade-up" data-aos-delay="500" className="tlm-btns">
                {/* <div data-bs-target="#SignUpBtns" data-bs-toggle="modal" className="tlm-btn-items tbi-fill"><span>Open Account</span></div> */}
                <a href="https://my.winprofx.com/en/register" target='_blank' rel="noreferrer" className="tlm-btn-items tbi-fill"><span>Open Account</span></a>
                {/* <div onClick={() => handleScreenChange('register-screen')} className="tlm-btn-items tbi-fill"><span>Open Account</span></div> */}
                {/* <div className="tlm-btn-items tbi-none">Try Demo <img src={Img.arrowright} alt="" /> </div> */}
            </div>
          </div>
        </div>
        <div className="tbb-bottom textlayout-bg-bx bdfhbsrh">
          <img data-aos="fade-up" data-aos-delay="900" src={Img.maps} alt="" />
        </div> 
      </section>

      <div className="py-3"></div>

      <section className="relative maxwidth1920">
        {/* <div className="container-lg cl-custome"> */}
          <Forexslider />
        {/* </div> */}
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="common-section">
            <div id="faqsaccordion" className="faqs-accordion mt-4" > {/*  data-bs-parent="#faqsaccordion" */}
              {questions.map((item, index) => (
              <div data-aos="fade-up" className="acc-item" key={index}>
                  <div className="acc-heding collapsed" id={`heading${index}`} data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                    {item.question}
                    <span className="down-icon">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                      </svg> */}
                    </span>
                  </div>

                  <div id={`collapse${index}`} className="collapse" aria-labelledby={`heading${index}`}>
                      <div className="faqs-data">
                        {item.answer}
                      </div>
                  </div>
              </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx py-0">
            <div data-aos="fade-up" className="tlm-heading heading-24">Critical Insights: Unveiling the <span className="tlm-h-color">Pinnacle Learnings</span> for <br/> Strategic Success.</div>
            <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">Beginners and experienced forex traders alike must keep in mind that practice, knowledge, and discipline are key to getting and staying ahead. The Best Forex Brokers Platform can help achieve success.</div>
            <div data-aos="fade-up" data-aos-delay="500" className="tlm-btns">
            <a href='https://api.whatsapp.com/send?phone=+971562520783&text=Hello' className="tlm-btn-items tbi-fill"><span><img className='whatappimg' src={Img.whatsapp} alt="" />WhatsApp</span></a>
            </div> 
          </div>
        </div>
      </section>
    </>
  );
};
