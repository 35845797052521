import React, { useState, useEffect, useRef } from "react";
import {Link} from "react-router-dom";
// import { Helmet } from "react-helmet-async";
import CommonHelmet from '../../components/CommonHelmet';

// Phone Number Contry Code
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

import Swal from 'sweetalert2';
import axios from 'axios';
import API_HOST from '../../config/config';
import countries from '../../config/country';
import * as Img from '../../components/Img';


// Design Files
// import { useMenu } from '../../components/Header/MenuContext';
import Socialicon from '../../components/Socialicon';

// Images Common File
import phone from '../../img/icons/contact/phone.svg'
import whatsApp from '../../img/icons/contact/whatsApp.svg'
import at from '../../img/icons/contact/at.svg'
import location from '../../img/icons/contact/location.svg'


const MOBILE_REGEX = /^[0-9]{10}$/;
const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;


export default function PageContact() {
  // const { handleScreenChange } = useMenu();

    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');
    const [contactFormButton, setContactFormButton] = useState(true);

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    const nameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const countryRef = useRef();
    const messageRef = useRef();

    const [name, setName] = useState('');
    const [validName, setValidName] = useState(true);
    const [nameMsg, setNameMsg] = useState('');

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(true);
    const [emailMsg, setEmailMsg] = useState('');

    const [country, setCountry] = useState('');
    const [validCountry, setValidCountry] = useState(true);
    const [countryMsg, setCountryMsg] = useState('');
    
    const [phoneValue, setPhoneValue] = useState('');
    const [phoneMsg, setPhoneMsg] = useState('');
    const [isValid, setIsValid] = useState(true);

    const handleInputChange = (val) => {
        setPhoneValue(val || '');
        setIsValid((!!val && isValidPhoneNumber((val || ''))));
        if(val == '')
        {
            setPhoneMsg('Phone Number is required');
        }
        else if((!!val && isValidPhoneNumber((val || ''))) == false)
        {
            setPhoneMsg('Please enter a valid number.');
        }
        else if(val != '' && ((!!val && isValidPhoneNumber((val || ''))) == true))
        {
            setPhoneMsg('');
        }    
    };

    const phoneNumberCheck = () => {
        setIsValid((!!phoneValue && isValidPhoneNumber(phoneValue || '')));
        if(phoneValue == '')
        {
            setPhoneMsg('Phone Number is required');
        }
        else if((!!phoneValue && isValidPhoneNumber((phoneValue || ''))) == false)
        {
            setPhoneMsg('Please enter a valid number.');
        }
        else if(phoneValue != '' && ((!!phoneValue && isValidPhoneNumber((phoneValue || ''))) == true))
        {
            setPhoneMsg('');
        }
        else
        {
            setPhoneMsg('');
        }
        return (!!phoneValue && isValidPhoneNumber((phoneValue || '')));
    }

    const [message, setMessage] = useState('');
    const [validMessage, setValidMessage] = useState(true);
    const [messageMsg, setMessageMsg] = useState('');
    const [ogImageUrl , setOgImageUrl] = useState('https://adstars.pmcommu.in/public/adstar.jpg');

    useEffect(() => {},[name,email,phoneValue,country,message]);

    function checkName(value)
    {
        setName(value);
        if(value != ""){ setValidName(true); }else{ setValidName(false); }
    }

    function checkCountry(value)
    {
        setCountry(value);
        if(value != ""){ setValidCountry(true); }else{ setValidCountry(false); }
    }

    function checkEmail(value)
    {
        setEmail(value);
        if(value != ""){
            const email_result = EMAIL_REGEX.test(value);
            setValidEmail(email_result);
        }else{ 
            setValidEmail(false); 
        }
    } 

    function checkMessage(value)
    {
        setMessage(value);
        if(value != ""){ setValidMessage(true); }else{ setValidMessage(false); }
    }

    function contactFormSubmitCheck () {
        var checkVal = [];
        
        if(name != ""){ checkVal = [...checkVal,true]; setValidName(true); }else{ checkVal = [...checkVal,false]; setValidName(false); }
        if(country != ""){ setValidCountry(true); checkVal = [...checkVal,true]; }else{ setValidCountry(false); checkVal = [...checkVal,false]; }  
        if(message != ""){ checkVal = [...checkVal,true]; setValidMessage(true); }else{ checkVal = [...checkVal,false]; setValidMessage(false); }        
        if(phoneValue != ""){
            const phone_result = phoneNumberCheck();
            setIsValid(phone_result);
            checkVal = [...checkVal,phone_result];
        }else{ 
            setIsValid(false); 
            checkVal = [...checkVal,false];
        }

        if(email != ""){
            const email_result = EMAIL_REGEX.test(email);
            setValidEmail(email_result);
            checkVal = [...checkVal,email_result];
        }else{ 
            setValidEmail(false);
            checkVal = [...checkVal,false];
        }
           
        if(checkVal.includes(false)) { return false; } else { return true; }
    }

    const handleContactFormSubmit = async (e) => {
        e.preventDefault();        
        if(!contactFormSubmitCheck())
        {
            setErrMsg("");
            return;
        }
        else{
            setErrMsg('');
            setValidName(true);
            setValidCountry(true);
            setIsValid(true);
            setValidEmail(true);
            setValidMessage(true);
        }

        try {
            setContactFormButton(false);
            var phoneValueGet = '';
            if((isValidPhoneNumber((phoneValue || ''))) == true)
            {
                const phoneNumber = parsePhoneNumber(phoneValue);
                if (phoneNumber) {
                    phoneValueGet = `${phoneNumber.number}`;
                }
            }
            const response = await axios.post(API_HOST+'/xhr/save-contact.php', { 
                name : `${name}`,
                phone : `${phoneValueGet}`,
                email : `${email}`,
                country : `${country}`,
                message : `${message}`,
            },{headers: {
                "Content-Type": "multipart/form-data",
                withCredentials: true
            }},)
            .then(response => {
                if(response.data.error == '0' || response.data.error == 0)
                {
                    setErrMsg('');
                    setName('');
                    setValidName(true);
                    setNameMsg('');
                    setCountry('');
                    setValidCountry(true);
                    setCountryMsg('');                    
                    setEmail('');
                    setValidEmail(true);
                    setEmailMsg('');
                    setPhoneValue('');
                    setIsValid(true);
                    setPhoneMsg('');
                    setMessage('');
                    setValidMessage(true);
                    setMessageMsg('');
                    setContactFormButton(true);
                    Toast.fire({
                      icon: 'success',
                      title: `${response.data.msg}`
                    });
                }                
            })
            .catch(error => {
                if(!error?.response)
                {
                    Toast.fire({
                        icon: 'error',
                        title: "No Server Response."
                    });
                }
                else if(error.response.data.error == 1)
                {
                    if(typeof error.response.data.data == 'object' && error.response.data.data !== null && Object.keys(error.response.data.data).length > 0)
                    {   
                        if('name' in error.response.data.data)
                        {
                            setNameMsg(error.response.data.data.name.toString());
                            setValidName(false);
                        }

                        if('phone' in error.response.data.data)
                        {
                            setPhoneMsg(error.response.data.data.phone.toString());
                            setIsValid(false);
                        }

                        if('email' in error.response.data.data)
                        {
                            setEmailMsg(error.response.data.data.email.toString());
                            setValidEmail(false);
                        }

                        if('message' in error.response.data.data)
                        {
                            setMessageMsg(error.response.data.data.message.toString());
                            setValidMessage(false);
                        }
                    }  
                    Toast.fire({
                        icon: 'error',
                        title: "contact us not send."
                    });                  
                }
                else if(error.response?.status === 409 )
                {
                    Toast.fire({
                        icon: 'error',
                        title: "contact us not send."
                    });
                }
                else{
                    Toast.fire({
                        icon: 'error',
                        title: "contact us not send."
                    });
                }
                setContactFormButton(true);
            });
            setContactFormButton(true);
        } catch (err) {
            setContactFormButton(true);
            console.error('Error fetching data catch:', err);
        }
    }

  const contactinfo = [

    {
        contactClassName: "col-lg-3 col-sm-4 col-sm-6 col-6",
        contactName: "WhatsApp",
        contactImg: whatsApp,
        // contactData: "(+971) 586223204",
        contactData: "whatsapp",
        contactLink: "https://api.whatsapp.com/send?phone=+971562520783&text=Hello",
    },
    {
        contactClassName: "col-lg-3 col-md-4 col-sm-6",
        contactName: "Email Us",
        contactImg: at,
        contactData: "support@winprofx.com",
        contactLink: "mailto:support@winprofx.com",
    },
    {
        contactClassName: "col-lg-3 col-md-4 col-sm-6",
        contactName: "Call Us",
        contactImg: phone,
        contactData: "+971 4 447 1894",
        contactLink: "tel:+97144471894",
    },
    {
        contactClassName: "col-lg-3 col-md-4 col-sm-6",
        contactName: "Address",
        contactImg: location,
        // contactData: "1st Floor  The Sotheby Building Rodney Bay Gros-Islet,SAINT Lucia P.O Box 838 Castries Saint Lucia.",
        contactData: "P.O Box 838 Castries, Saint Lucia.",
        contactLink: "/",
    },
  ]

  return (
    <>
    {/*  <Helmet>
    <title>Contact Us | WinproFx</title>
    </Helmet> */}
    <CommonHelmet title="Contact Us | WinproFx" />

      <section className="relative">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx">
            <div className="breadcrumb-bx">
                <Link className="breadcrumb-link" to="/">Home</Link>
                <div className="breadcrumb-link breadcrumb-active">/ Contact Us</div>
            </div>
            <div data-aos="fade-up" className="tlm-heading">Connect with WinproFX: Unleash Your <br/> Trading Potential</div>
            <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">Embark on a journey to success with WinproFX, where your questions and feedback become the compass guiding our commitment to excellence.</div>
            <div data-aos="fade-up" data-aos-delay="500">
                <Socialicon className="social-icon" />
            </div>
          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="">
        <div className="container-lg cl-custome py-5">
            <div className="common-section">
                <div className="common-heading-bx">
                    <div className="common-heading">Let’s Get In Touch</div>
                </div>

                <form onSubmit={handleContactFormSubmit} className="mt-4">
                    <div className={`otp-sent-msg text-start mb-4 ${errMsg ? "" : "d-none"} `}>
                        <p ref={errRef} className={`form-error-msg text-start ${errMsg ? "" : "d-none"} `} aria-live="assertive" >{errMsg}</p>
                    </div> 
                    <div className="row">

                        <div className="col-sm-6">
                            <div className={`signinup-group ${name=='' && !validName ? "error-group" : ""} `}>
                                <label className="group__label">Name</label>
                                <input type="text" id="name" ref={nameRef} autoComplete="off" onChange={(e) => checkName(e.target.value)} aria-invalid={validName ? "false" : "true"} aria-describedby="name_error" placeholder="Enter your full name here.." value={name} />
                                <span id="name_error" className={`form-error-msg mb-1 ${name=='' && !validName ? "" : "d-none"} `}>{nameMsg != '' ? nameMsg : 'Please enter valid name'}</span>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className={`signinup-group sg-country-code ${phoneValue=='' && !isValid ? "error-group" : ""} `}>
                                <div className="group__label">Phone No.</div>
                                <PhoneInput 
                                // international 
                                countryCallingCodeEditable={false} defaultCountry="AE"  placeholder="Enter phone number" id="phone" ref={phoneRef} value={phoneValue} onChange={handleInputChange}/>
                                {/* <input type="number" placeholder="Enter your Phone no. here"/>
                                <span className="group_left_icon">+1</span> */}
                                <span id="phone_error" className={`form-error-msg mb-1 ${!isValid ? "" : "d-none"} `}>{phoneMsg != '' ? phoneMsg : 'Please enter valid number'}</span>
                            </div>                            
                        </div>

                        <div className="col-sm-6">
                            <div className={`signinup-group ${email=='' && !validEmail ? "error-group" : ""} `}>
                                <label className="group__label">Email</label>
                                <input type="email" id="email" ref={emailRef} autoComplete="off" onChange={(e) => checkEmail(e.target.value)} aria-invalid={validEmail ? "false" : "true"} aria-describedby="email_error" placeholder="Enter your email here.." value={email} />
                                <span id="email_error" className={`form-error-msg mb-1 ${!validEmail ? "" : "d-none"} `}>{emailMsg != '' ? emailMsg : 'Please enter valid email'}</span>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className={`signinup-group sgri ${country=='' && !validCountry ? "error-group" : ""}`}>
                                <label className="group__label">Country</label>
                                <select id="country" ref={countryRef} onChange={(e) => checkCountry(e.target.value)}  aria-invalid={validCountry ? "false" : "true"} aria-describedby="country_error" value={country} >
                                    <option value="">Select Country</option>
                                    {countries.map((country, index) => (
                                        <option key={index} value={country}>{country}</option>
                                    ))}
                                    {/* <option>India</option>
                                    <option>UAE</option>
                                    <option>USA</option>                     */}
                                </select>
                                <span className="down-icon"></span>
                                <span id="country_error" className={`form-error-msg ${country=='' && !validCountry ? "" : "d-none"} `}>{countryMsg != '' ? countryMsg : 'Please Select Country'}</span>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className={`signinup-group ${message=='' && !validMessage ? "error-group" : ""} `}>
                                <label className="group__label">Message</label>
                                <textarea id="message" ref={messageRef} onChange={(e) => checkMessage(e.target.value)} aria-invalid={validMessage ? "false" : "true"} aria-describedby="message_error" placeholder="Enter your message here.." value={message} ></textarea>
                                {/* <span className="twl-lable">0/250 word</span> */}
                                <span id="message_error" className={`form-error-msg mb-1 ${message=='' && !validMessage ? "" : "d-none"} `}>{messageMsg != '' ? messageMsg : 'Please enter valid message'}</span>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="signinup-group-checkmark">
                                <input className="d-none" type="checkbox" id="checkmark1"/>
                                <label className="checkmark-lable" htmlFor="checkmark1"><span className="checkmark-icon"></span>Here at WinproFX we take your privacy seriously and will only use your personal information to administer your account and to provide the products and services you have requested from us. However, from time to time we would like to contact you with details of other products, offers and services we provide. If you consent to us contacting you for this purpose please tick to say you would like us to contact you. I have read and agree to the terms & conditions and privacy & cookies policy.</label>
                            </div>
                        </div>

                    </div>
                    <button className={`width576full common-btn-item cbi-fill mt-2 ${contactFormButton ? '' : 'd-none'} `} >Submit</button>
                    <button type="button" className={`width576full common-btn-item cbi-fill mt-2 lsb-loader-btn ms-0 mb-0 ${contactFormButton ? 'd-none' : ''} `}>
                        <img src={Img.loading} alt="" />Please wait ....
                    </button>
                </form>

            </div>
        </div>   
      </section>

      <section className="py-5 overflowhidden">
        <div className="container-lg cl-custome">
          <div className="common-section">
            <div className="row">
              {contactinfo.map((item, index) => ( 
                <div data-aos="fade-left" className={item.contactClassName} key={index}>
                    <a className="contact-info" href={item.contactLink}>
                        <span className="contact-icon">
                            <img src={item.contactImg} alt="" />
                        </span>
                        <div className="contact-text">
                            <h6>{item.contactName}</h6>
                            <p>{item.contactData}</p>
                        </div>
                    </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx py-0">
            <div data-aos="fade-up" className="tlm-heading heading-24">Embark on a transformative trading journey with us — <br/> where trading isn't just an action, it's a difference. Trade <br/> with us, be the difference.</div>
            <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">Dive into a world where every trade creates a ripple of impact. Join us in shaping a unique trading experience that not only sets you apart but also makes a positive difference. Trade with purpose, trade with us.</div>
            <div data-aos="fade-up" data-aos-delay="500" className="tlm-btns">
                {/* <div data-bs-target="#SignInBtns" data-bs-toggle="modal" className="tlm-btn-items tbi-fill"><span>Start your Journey today</span></div> */}
                <a href="https://my.winprofx.com/en/login" target='_blank' rel="noreferrer" className="tlm-btn-items tbi-fill"><span>Start your Journey today</span></a>
                {/* <div onClick={() => handleScreenChange('register-screen')} className="tlm-btn-items tbi-fill"><span>Start your Journey today</span></div> */}
            </div> 
          </div>
        </div>
      </section>
    </>
  );
};
