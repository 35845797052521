import React from 'react';

// Slider Code
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import Component1 from '../../img/icons/layer/Component(1).svg';
import Component2 from '../../img/icons/layer/Component(2).svg';
// import Component3 from '../../img/icons/layer/Component(3).svg';
import Component4 from '../../img/icons/layer/Component(4).svg';
import Component5 from '../../img/icons/layer/Component(6).png';

export default function BenefitsSlider() {
  const benefitsslider = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    swipeToSlide:true,
    // className: "center",
    centerMode: true,
    speed: 500,
    autoplaySpeed: 3000,
    responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            centerPadding: "6%",
          }
        }
      ]
  };
  return (
    <>
        <div data-aos="fade-up">
            <Slider className={`benefitsslider-bx mt-4`} {...benefitsslider}>

            <div className="card-item-flip p-2">
                <div className="ctf-inner">
                <div className="card-item cif-befor">
                    <img className="card-i-img img-32" src={Component1} alt=""/>
                    <div>
                        <div className="card-i-text max-width-189">Crypto Withdraw in</div>
                        <div className="card-i-heading">30 minutes</div>
                    </div>
                </div>
                <div className="card-item cif-after">
                    <img className="card-i-img img-32" src={Component1} alt=""/>
                    <div className="card-i-text max-width-189">Crypto Withdraw in</div>
                    <div className="card-i-heading">30 minutes</div>
                    <div className="card-i-text">"Unlock swift financial flexibility with our 30-minute withdrawal guarantee."</div>
                </div>
                </div>
            </div>

    
            <div className="card-item-flip p-2">
                <div className="ctf-inner">
                <div className="card-item cif-befor">
                    <img className="card-i-img" src={Component2} alt=""/>
                    <div>
                        <div className="card-i-text">Trade daily</div>
                        <div className="card-i-heading">Win rewards</div>
                    </div>
                </div>
                <div className="card-item cif-after">
                    <img className="card-i-img" src={Component2} alt=""/>
                    <div className="card-i-text">Trade daily</div>
                    <div>
                        <div className="card-i-heading">Win rewards</div>
                        <div className="card-i-text">"Daily trades, daily wins – fuel your success in every market move."</div>
                    </div>
                </div>
                </div>
            </div>
                 
            <div className="card-item-flip p-2">
                <div className="ctf-inner">
                <div className="card-item cif-befor">
                    <img className="card-i-img" src={Component5} alt=""/>
                    <div>
                        <div className="card-i-text">Award Winning</div>
                        <div className="card-i-heading">Customer Support</div>
                    </div>
                </div>
                <div className="card-item cif-after">
                    <img className="card-i-img" src={Component5} alt=""/>
                    <div className="card-i-text">Award Winning</div>
                    <div className="card-i-heading">Customer Support</div>
                    <div className="card-i-text">Delivering unparalleled service excellence!</div>
                </div>
                </div>
            </div>
        
            <div className="card-item-flip p-2">
                <div className="ctf-inner">
                <div className="card-item cif-befor">
                    <img className="card-i-img" src={Component4} alt=""/>
                    <div>
                        <div className="card-i-text">Negative balance</div>
                        <div className="card-i-heading">Protection</div>
                    </div>
                </div>
                <div className="card-item cif-after">
                    <img className="card-i-img" src={Component4} alt=""/>
                    <div className="card-i-text">Negative balance</div>
                    <div className="card-i-heading">Protection</div>
                    <div className="card-i-text">"Trade confidently with built-in negative balance protection for financial security."</div>
                </div>
                </div>
            </div>
                 

            </Slider>
        </div>
    </>
  );
}
