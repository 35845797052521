import React from 'react';

// Slider Code
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';


// Images Common File
import * as Img from '../../components/Img';

/// Images Common File
import grap from '../../img/icons/grap.svg';

import trade13 from '../../img/trade/trade(13).svg'
import trade14 from '../../img/trade/trade(14).svg'
import trade15 from '../../img/trade/trade(15).svg'

export default function OilSlider() {
  const oilslider = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    swipeToSlide:true,
    className: "slidersidebox center",
    centerMode: true,
    speed: 500,
    autoplaySpeed: 3000,
    centerPadding: "28%",
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          centerPadding: "25%",
        }
      },
      {
        breakpoint: 1200,
        settings: {
          centerPadding: "12%",
        }
      },
      {
        breakpoint: 992,
        settings: {
          centerPadding: "6%",
        }
      }
    ]
  };
  return (
    <>
      <div data-aos="fade-up" className='arrowfullwidth'>
        <Slider className={`slick-hover mt-4`} {...oilslider}>

          <div className="forexslider-card-outer">
            <div className="forexslider-card-items">
              <div className="fci-right">
                <img src={grap} alt="" />
              </div>
              <div className="fci-left">

                  <div className="fci-img-bx">
                    <img src={trade13} alt="" />
                    {/* <img src={flag2} alt="" /> */}
                  </div>
                  
                  <div className="fci-country-name">
                  RBOB Gasoline Futures<span>RBOB Gasoline</span>
                  </div>

                  <div className="fci-rates">
                    <div className="fci-rate">1.0932<span>USD</span></div>
                    <div className="fci-status fci-red">−0.00072 −0.07%</div>
                  </div>

                  <div className="fci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

              </div>
            </div>
          </div>

          <div className="forexslider-card-outer">
            <div className="forexslider-card-items">
              <div className="fci-right">
                <img src={grap} alt="" />
              </div>
              <div className="fci-left">

                  <div className="fci-img-bx">
                    <img src={trade14} alt="" />
                    {/* <img src={flag2} alt="" /> */}
                  </div>
                  
                  <div className="fci-country-name">
                  NY Harbor ULSD Futures<span>NY Harbor ULSD</span>
                  </div>

                  <div className="fci-rates">
                    <div className="fci-rate">1.0932<span>USD</span></div>
                    <div className="fci-status fci-green">+0.00072 +0.07%</div>
                  </div>

                  <div className="fci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

              </div>
            </div>
          </div>

          <div className="forexslider-card-outer">
            <div className="forexslider-card-items">
              <div className="fci-right">
                <img src={grap} alt="" />
              </div>
              <div className="fci-left">

                  <div className="fci-img-bx">
                    <img src={trade15} alt="" />
                    {/* <img src={flag2} alt="" /> */}
                  </div>

                  <div className="fci-country-name">
                  ETHANOL FUTURES<span>ETHANOL</span>
                  </div>

                  <div className="fci-rates">
                    <div className="fci-rate">1.0932<span>USD</span></div>
                    <div className="fci-status fci-red">−0.00072 −0.07%</div>
                  </div>

                  <div className="fci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

              </div>
            </div>
          </div>

        </Slider>
      </div>
    </>
  );
}
