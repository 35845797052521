import React, { useState } from 'react';
import {Link} from "react-router-dom";
import CommonHelmet from '../../components/CommonHelmet';
// import { useMenu } from '../../components/Header/MenuContext';

import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';


// Images Common File
import * as Img from '../../components/Img';

import Capa from '../../img/icons/Capa.png';
import Broker from '../../img/icons/Broker.png';
import Commision from '../../img/icons/Commision.png';
import Reward from '../../img/icons/Reward.png';

import hand from '../../img/icons/hand.png';
import hourn from '../../img/icons/hourn.png';
import Start from '../../img/icons/Start.png';
import Team from '../../img/icons/Team.png';
import PRservices from '../../img/icons/PRservices.png';

import withdrawal from '../../img/icons/withdrawal.png';
import Swap from '../../img/icons/Swap.png';
import Commission from '../../img/icons/Commission.png';
import Spread from '../../img/icons/Spread.png';
// import vsdgsr from '../../img/bgimg/map-vector.svg';



export default function PageIntroducingBroker() {  

//   const { handleScreenChange } = useMenu();
    const [value, setValue] = useState("25");

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    const calculateAmount = (value) => {
        // Convert the value to a number
        const numericValue = parseInt(value);
        
        // Calculate the amount based on the range
        const amount = numericValue * 130;
        
        return amount.toLocaleString(); // Format the amount with commas
    };

  return (
    <>
        <CommonHelmet 
            title="Best Forex Trading Broker for Solutions & Services"
            description="Join the WinProFX Introducing Broker Program and earn rewards for referring new clients. Enjoy low commissions, top support, and a leading trading platform. Start now."
            keywords="Forex Introducing Broker, IB Program Rewards, Forex Broker Referral, Best Forex IB, Forex Trading Incentives, Forex Broker Support, Introducing Broker Opportunities, Forex Trading Platform, WinProFX IB, Best Forex Trading Broker Platform, Best Forex Market Trading Platform, Best Online Trading Platform For Professional Traders In India, Best Forex Market Trading Platforms For Advanced Traders, Best Forex Trading Platform For India Beginners, Best Trading Brokers For Beginners, Best Crypto Currency Trading Platform, Best Free Funded Forex Account Without Deposit, Forex Broker 0 Spread, Free Funded Account, Free Funded Accounts, Best Forex Trading Platform"
        />
        <div className="d-none" style={{display:"none"}}>
            <h1>Best Forex Trading Broker for Solutions & Services</h1>
            <h2>Join the WinProFX Introducing Broker Program and earn rewards for referring new clients. Enjoy low commissions, top support, and a leading trading platform. Start now.</h2>
            <b>Forex Introducing Broker</b>
            <b>IB Program Rewards</b>
            <b>Forex Broker Referral</b>
            <b>Best Forex IB</b>
            <b>Forex Trading Incentives</b>
            <b>Forex Broker Support</b>
            <b>Introducing Broker Opportunities</b>
            <b>Forex Trading Platform</b>
            <b>WinProFX IB</b>
            <img src="" alt="Forex Introducing Broker, IB Program Rewards, Forex Broker Referral, Best Forex IB, Forex Trading Incentives, Forex Broker Support, Introducing Broker Opportunities, Forex Trading Platform, WinProFX IB, Best Forex Trading Broker Platform, Best Forex Market Trading Platform, Best Online Trading Platform For Professional Traders In India, Best Forex Market Trading Platforms For Advanced Traders, Best Forex Trading Platform For India Beginners, Best Trading Brokers For Beginners, Best Crypto Currency Trading Platform, Best Free Funded Forex Account Without Deposit, Forex Broker 0 Spread, Free Funded Account, Free Funded Accounts, Best Forex Trading Platform" />
        </div>

        <section className="relative textlayout-white">
            <div className="container-lg cl-custome">
                <div className="textlayout-main-bx mt-5 mt-sm-0">
                    <div className="breadcrumb-bx">
                        <Link className="breadcrumb-link" to="/">Home</Link>
                        <div className="breadcrumb-link breadcrumb-active">/ Introducing Broker</div>
                    </div>
                    <div data-aos="fade-up" className="tlm-heading">Team up with WinproFX as a partner(IB) <br/> and let's make things awesome together!</div>
                    <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">WINPROFX Introducing Broker (IB) Program Rewards Individuals & Businesses for Introducing New Clients.</div>
                    <div data-aos="fade-up" data-aos-delay="500" className="tlm-sub-text">Our experienced forex brokers will help visionary individuals and institutions in setting up their own business and Introducing Broker. With our extensive experience in the Forex Industry, you can take the trading experience of your customers to the next level with an innovative platform, dedicated support, low commissions, and precise order execution. Choose the Best Forex Trading Broker Platform to enhance your trading success.</div>
                    <div data-aos="fade-up" data-aos-delay="600" className="tlm-btns">
                        {/* <div data-bs-target="#SignInBtns" data-bs-toggle="modal" className="tlm-btn-items tbi-fill"><span>Become a Partner</span></div> */}
                        <a href="https://my.winprofx.com/en/login" target='_blank' rel="noreferrer" className="tlm-btn-items tbi-fill"><span>Become a Partner</span></a>
                        {/* <div onClick={() => handleScreenChange('register-screen')} className="tlm-btn-items tbi-fill"><span>Become a Partner</span></div> */}
                    </div>
                </div>
                
            </div>

            <div className="textlayout-bg-bx affter-none caehydgfvusyedgbf esgdsgsrxh">
                <div data-aos="fade-up" data-aos-delay="900" >
                    <img className="afegsrghsdr"  src={Img.map} alt="" />
                </div>
            </div> 
        </section>

        <div className="py-2"></div>

        <section className="pt-5 pb-1">
            <div className="container-lg cl-custome">
                <div className="common-section">

                    <div className="common-heading-bx chb-white">
                        <div className="common-heading">Partnering Perks: Commission Riches, Award-Winning <br/> Trust, Market Evolution, and Daily Win Rewards!</div>
                    </div>

                    <div className="row mt-5">
                    
                        <div data-aos="fade-up" className="col-lg-3 col-6 mb-4">
                            <div className="card-item ci-white">
                                <img className="card-i-img img-84" src={Commision} alt=""/>
                                <div className="card-i-text max-width-189">Commission up to 80% spread revshare</div>
                            </div>
                        </div>

                        <div data-aos="fade-up" data-aos-delay="400" className="col-lg-3 col-6 mb-4">
                            <div className="card-item ci-white">
                                <img className="card-i-img img-84" src={Broker} alt=""/>
                                <div className="card-i-text max-width-189">Reputable Broker With 40+ Industry awards</div>
                            </div>
                        </div>

                        <div data-aos="fade-up" data-aos-delay="500" className="col-lg-3 col-6 mb-4">
                            <div className="card-item ci-white">
                                <img className="card-i-img img-84" src={Capa} alt=""/>
                                <div className="card-i-text max-width-189">Evolving everyday in the market</div>
                            </div>
                        </div>

                        <div data-aos="fade-up" data-aos-delay="600" className="col-lg-3 col-6 mb-4">
                            <div className="card-item ci-white">
                                <img className="card-i-img img-84" src={Reward} alt=""/>
                                <div className="card-i-text max-width-189">Trade and Win Rewards Everyday</div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>

        <div className="py-2"></div>

        <section className="pt-5 pb-5">
            <div className="container-lg cl-custome">
                <div className="common-section">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="common-heading-bx chb-fd-row chb-white">
                                <div className="common-heading">What Sets Us Apart: <br/> Reasons for Client Preference</div>
                                {/* <div onClick={() => handleScreenChange('register-screen')} className="d-none d-md-flex common-btn-item cbi-white cbi-fill"><span>Become a Partner</span></div> */}
                                <a href="https://my.winprofx.com/en/login" target='_blank' rel="noreferrer" className="d-none d-md-flex common-btn-item cbi-white cbi-fill"><span>Become a Partner</span></a>
                                {/* <div data-bs-target="#SignInBtns" data-bs-toggle="modal" className="d-none d-md-flex common-btn-item cbi-white cbi-fill"><span>Become a Partner</span></div> */}
                            </div>
                            
                            <div className="row mt-4">
                                
                                <div data-aos="fade-up" className="col-md-3 col-sm-6 col-6 mt-4">
                                    <div className="card-item ci-border ci-white">
                                        <img className="card-i-img img-32 mb-5" src={withdrawal} alt=""/>
                                        <div className="card-i-heading cih-darkblue mt-4">Fastest <br/> Withdrawal</div>
                                    </div>
                                </div>

                                <div data-aos="fade-up" data-aos-delay="400" className="col-md-3 col-sm-6 col-6 mt-4">
                                    <div className="card-item ci-border ci-white">
                                        <img className="card-i-img img-32 mb-5" src={Swap} alt=""/>
                                        <div className="card-i-heading cih-darkblue mt-4">No <br/> swap</div>
                                    </div>
                                </div>

                                <div  data-aos="fade-up" data-aos-delay="500"className="col-md-3 col-sm-6 col-6 mt-4">
                                    <div className="card-item ci-border ci-white">
                                        <img className="card-i-img img-32 mb-5" src={Commission} alt=""/>
                                        <div className="card-i-heading cih-darkblue mt-4">No <br/> Commission</div>
                                    </div>
                                </div>

                                <div data-aos="fade-up" data-aos-delay="600" className="col-md-3 col-sm-6 col-6 mt-4">
                                    <div className="card-item ci-border ci-white">
                                        <img className="card-i-img img-32 mb-5" src={Spread} alt=""/>
                                        <div className="card-i-heading cih-darkblue mt-4">Low <br/> Spread</div>
                                    </div>
                                </div>

                            </div>

                            {/* <div className="common-heading-bx chb-fd-row chb-white mt-4">
                                <div onClick={() => handleScreenChange('register-screen')} className="d-flex d-md-none common-btn-item cbi-white cbi-fill"><span>Become a Partner</span></div>
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div className="py-2"></div>

        <section className="pt-5 pb-5 bg-darkblue-100 overflowhidden">
            <div className="container-lg cl-custome">
                <div className="common-section">

                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="common-heading-bx chb-white">
                                <div className="common-heading">Benefits Of Becoming Introducing Broker With WinproFX</div>
                                <div className="common-sub-heading mt-0 mt-md-4">Unlock a world of advantages by becoming an Introducing Broker with WinproFX. Enjoy generous commission structures, industry recognition, continuous market evolution, and the opportunity to empower traders with everyday rewards. Join us in reshaping the future of trading partnerships with the Best Broker Trading Platform.</div>
                            </div>
                        </div>
                        <div className="col-md-5 mt-4 mt-md-0">

                            <div data-aos="fade-up-left" className="card-item ci-flex-row ci-white">
                                <img className="card-i-img img-circle img-84" src={Team} alt=""/>
                                <div>
                                    <div className="card-i-heading cih-darkblue">Multi-tier up to 5 levels</div>
                                    <div className="card-i-text">As an IB you could have up to 5 sub levels of referral traders</div>
                                </div>
                            </div>

                            <div data-aos="fade-up-left" className="card-item ci-flex-row ci-white">
                                <img className="card-i-img img-circle img-84" src={Start} alt=""/>
                                <div>
                                    <div className="card-i-heading cih-darkblue">Easy to get Started</div>
                                    <div className="card-i-text">No mountains of paperwork to sign or difficult IT implementation required</div>
                                </div>
                            </div>

                            <div data-aos="fade-up-left" className="card-item ci-flex-row ci-white">
                                <img className="card-i-img img-circle img-84" src={PRservices} alt=""/>
                                <div>
                                    <div className="card-i-heading cih-darkblue">Personalized service</div>
                                    <div className="card-i-text">A personal IB account manager would be assigned to support you</div>
                                </div>
                            </div>

                            <div data-aos="fade-up-left" className="card-item ci-flex-row ci-white">
                                <img className="card-i-img img-circle img-84" src={hourn} alt=""/>
                                <div>
                                    <div className="card-i-heading cih-darkblue">Promotion Support</div>
                                    <div className="card-i-text">Frequent promotional events for IB to attract and convert clients</div>
                                </div>
                            </div>

                            <div data-aos="fade-up-left" className="card-item ci-flex-row ci-white">
                                <img className="card-i-img img-circle img-84" src={hand} alt=""/>
                                <div>
                                    <div className="card-i-heading cih-darkblue">Fast Payout Settlement</div>
                                    <div className="card-i-text">As an IB the payment should be fast</div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </section>

        <div className="py-2 bg-darkblue"></div>

        <div id="timepass"></div>

        <section className="pt-5 pb-5 bg-darkblue">
            <div className="container-lg cl-custome">
                <div className="common-section">
                    <div className="row justify-content-center">
                        <div data-aos="fade-up" className="col-lg-10">
                            <div className="common-heading-bx">
                                <div className="common-heading">How much you can earn</div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-sm-6 pb-4 order-sm-1 order-1">
                                    <div className="card-item">
                                        <div className="card-i-heading color-electricblue">Calculate your profit</div>
                                        <div className="card-i-text">This advanced commission calculator operates on the basis of Introducing Brokers' client count and their trading volume in lots, providing an accurate reflection of earned commissions.</div>
                                    </div>
                                </div>
                                <div className="col-sm-6 pb-4 order-sm-2 order-3 mt-4 mt-sm-0">
                                    <div className="card-item text-sm-end">
                                        <div className="card-i-text">How much you gain</div>
                                        <div className="card-i-heading color-electricblue" style={{fontSize:"32px"}}>$ {calculateAmount(value)}</div>
                                        <div className="card-i-heading color-electricblue" style={{fontSize:"12px"}}>Per month</div>
                                    </div>
                                </div>
                                <div className="col-12 order-sm-3 order-2">

                
                                    <div className="range-slider-bx">
                                        <div className="range-slider-value">{value} Client</div>
                                        <InputRange
                                            maxValue={1000}
                                            minValue={1}
                                            value={value}
                                            onChange={handleChange}
                                        />
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div className="py-lg-2 bg-darkblue"></div>

        <section className="pt-0 pt-lg-5 pb-5 bg-darkblue overflowhidden">
            <div className="container-lg cl-custome">
                <div className="common-section">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="common-heading-bx chb-fd-row align-items-end">
                                <div>
                                    <div className="common-heading">Become An Winprofx Partner <br/> And Start Earning Today</div>
                                    <div className="common-sub-heading mt-0 mt-md-4">Embark on a lucrative journey by becoming a WinproFX Partner and kickstart your earnings today. Join our dynamic network, harness rewarding opportunities, and pave the way for financial success together. Partner with the Best Trading Brokers For Beginners and ensure a smooth start to your trading journey.</div>
                                </div>
                                {/* <div data-bs-target="#SignInBtns" data-bs-toggle="modal" className="d-none d-md-flex common-btn-item cbi-white cbi-fill"><span>Become a Partner</span></div> */}
                                <a href="https://my.winprofx.com/en/login" target='_blank' rel="noreferrer" className="d-none d-md-flex common-btn-item cbi-white cbi-fill"><span>Become a Partner</span></a>
                                {/* <div onClick={() => handleScreenChange('register-screen')} className="d-none d-md-flex common-btn-item cbi-white cbi-fill"><span>Become a Partner</span></div> */}
                            </div>

                            <div className="step-card-item mt-4">
                                
                                <div data-aos="fade-up-left" className="sci-outer">
                                    <div className="card-item">
                                        <div className="card-i-line"></div>
                                        <div className="card-i-num">1</div>
                                        <div className="card-i-text">Apply to become an introducing broker</div>
                                    </div>
                                </div>
                    
                                <div data-aos="fade-up-left" data-aos-delay="400" className="sci-outer">
                                    <div className="card-item">
                                        <div className="card-i-line"></div>
                                        <div className="card-i-num">2</div>
                                        <div className="card-i-text">Attract client by sharing your referral link</div>
                                    </div>
                                </div>
                    
                                <div data-aos="fade-up-left" data-aos-delay="500" className="sci-outer">
                                    <div className="card-item">
                                        <div className="card-i-line"></div>
                                        <div className="card-i-num">3</div>
                                        <div className="card-i-text">receive daily commission payouts</div>
                                    </div>
                                </div>
                               
                            </div>

                            <div className="common-heading-bx chb-fd-row chb-white mt-4">
                                {/* <div data-bs-target="#SignInBtns" data-bs-toggle="modal" className="d-flex d-md-none common-btn-item cbi-white cbi-fill"><span>Become a Partner</span></div> */}
                                <a href="https://my.winprofx.com/en/login" target='_blank' rel="noreferrer" className="d-flex d-md-none common-btn-item cbi-white cbi-fill"><span>Become a Partner</span></a>
                                {/* <div onClick={() => handleScreenChange('register-screen')} className="d-flex d-md-none common-btn-item cbi-white cbi-fill"><span>Become a Partner</span></div> */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div className="pb-5 bg-darkblue" style={{marginBottom: "-3rem"}}></div>



    </>
  );
};
