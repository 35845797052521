import React from 'react';


// Images Common File
import * as Img from '../components/Img';

export default function Commonsection() {

  return (
    <>
<div className="row justify-content-center">

    <div className="col-md-5 col-sm-6">
        <div data-aos="fade-right" className="property-group-bx">

            <div className="property-group-img">
                <img src={Img.mastercard} alt="" />
            </div>

            <div className="property-group-img">
                <img src={Img.visa} alt="" />
            </div>

            <div className="property-group-img">
                <img src={Img.bitcoin} alt="" />
            </div>
        
            <div className="property-group-img">
                <img src={Img.ethereum} alt="" />
            </div>

            <div className="property-group-img">
                <img src={Img.tether} alt="" />
            </div>
    
            <div className="property-group-img">
                <img src={Img.image1} alt="" />
            </div>
        
            <div className="property-group-img">
                <img src={Img.maskgroup} alt="" />
            </div>
        
            {/* <div className="property-group-img">
                <img src={Img.upi} alt="" />
            </div> */}
        
            <div className="property-group-img">
                <img src={Img.bankt} alt="" />
            </div>
           
        </div>
    </div>

    <div className="col-md-1 d-none d-lg-block"></div>

    <div className="col-lg-4 col-md-5 col-sm-6">
        <div className="common-heading-bx">
            <div className="common-heading">Effortless Fund Flow: Swift <br/> Deposits and Seamless <br/> <span className="ch-color">Withdrawals</span></div>
            <div className="common-sub-heading mt-md-5">Experience financial fluidity with our hassle-free deposit and withdrawal processes, ensuring your transactions are both quick and convenient.</div>
        </div>
    </div>

</div>
    </>
  );
};