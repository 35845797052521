import React from 'react'
import { Link } from "react-router-dom"
import CommonHelmet from '../../components/CommonHelmet';

const Cog6Svg = () => {
    return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
    </svg>
  );
};

export default function PageSitemap() {

  return (
    <>
        <CommonHelmet title="Sitemap | WinproFX" />

        <section className="relative">
            <div className="container-lg cl-custome">
                <div className="textlayout-main-bx pb-3">
                    <div className="breadcrumb-bx">
                        <Link className="breadcrumb-link" to="/">Home</Link>
                        <div className="breadcrumb-link breadcrumb-active">/ Sitemap</div>
                    </div>
                    <div className="tlm-heading">Sitemap</div>
                </div>
            </div>
        </section>

        <section className="pb-5 pt-2">
            <div className="container-lg cl-custome">
                <div className="row">
                    <div className="col-12 mb-4">
                        <div className="sitemap-heading">Markets</div>
                        <ul className="sitemap-links-ul">
                            <li className="slu-li"><Link to="/forex" className="slul-link"><Cog6Svg/>Forex</Link></li>
                            <li className="slu-li"><Link to="/indices" className="slul-link"><Cog6Svg/>Indices</Link></li>
                            <li className="slu-li"><Link to="/crypto" className="slul-link"><Cog6Svg/>Crypto</Link></li>
                            <li className="slu-li"><Link to="/metals" className="slul-link"><Cog6Svg/>Metals</Link></li>
                            <li className="slu-li"><Link to="/commodities" className="slul-link"><Cog6Svg/>Commodities</Link></li>
                            <li className="slu-li"><Link to="/oil" className="slul-link"><Cog6Svg/>Oil</Link></li>
                        </ul>
                    </div>
                    <div className='py-2'></div>
                    <div className="col-12 mb-4">
                        <div className="sitemap-heading">Explore</div>
                        <ul className="sitemap-links-ul">
                            <li className="slu-li"><Link to="/" className="slul-link"><Cog6Svg/>Home</Link></li>
                            <li className="slu-li"><Link to="/platform" className="slul-link"><Cog6Svg/>Platform</Link></li>
                            <li className="slu-li"><Link to="/about" className="slul-link"><Cog6Svg/>About Us</Link></li>
                            <li className="slu-li"><Link to="/introducing-broker" className="slul-link"><Cog6Svg/>Our IB</Link></li>
                            <li className="slu-li"><Link to="/free-funded-accounts" className="slul-link"><Cog6Svg/>Free Funded Accounts</Link></li>
                            <li className="slu-li"><Link to="/education" className="slul-link"><Cog6Svg/>Education</Link></li>
                            <li className="slu-li"><Link to="/news" className="slul-link"><Cog6Svg/>News & Insights</Link></li>
                            <li className="slu-li"><Link to="/join-our-team" className="slul-link"><Cog6Svg/>Join Our Team</Link></li>
                            <li className="slu-li"><Link to="/contact" className="slul-link"><Cog6Svg/>Contact Us</Link></li>
                            <li className="slu-li"><Link to="https://my.winprofx.com/en/login" target='_blank' className="slul-link"><Cog6Svg/>Login</Link></li>
                            <li className="slu-li"><Link to="https://my.winprofx.com/en/register" target='_blank' className="slul-link"><Cog6Svg/>Register</Link></li>
                        </ul>
                    </div>
                    <div className='py-2'></div>
                    <div className="col-12">
                        <div className="sitemap-heading">Our Policies</div>
                        <ul className="sitemap-links-ul">
                            <li className="slu-li"><Link to="/privacy-policy" className="slul-link"><Cog6Svg/>Privacy Policy</Link></li>
                            <li className="slu-li"><Link to="/terms-and-conditions" className="slul-link"><Cog6Svg/>Terms & Conditions</Link></li>
                            <li className="slu-li"><Link to="/aml-policy" className="slul-link"><Cog6Svg/>AML Policy</Link></li>
                            <li className="slu-li"><Link to="/deposit-withdrawal-policy" className="slul-link"><Cog6Svg/>Deposit & withdrawal Policy</Link></li>
                            <li className="slu-li"><Link to="/restricted-countries" className="slul-link"><Cog6Svg/>Restricted Countries</Link></li>
                            <li className="slu-li"><Link to="/risk-disclosure" className="slul-link"><Cog6Svg/>Risk Disclosure</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

    </>
  )
}