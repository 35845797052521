import React from 'react';

// Slider Code
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

/// Images Common File
import hqdefault from '../../img/hqdefault.png';

export default function Educationslider() {
  const educationslider = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    swipeToSlide:true,
    className: "center",
    centerMode: true,
    speed: 500,
    autoplaySpeed: 3000,
    centerPadding: "30%",
    responsive: [
        {
          breakpoint: 992,
          settings: {
            centerPadding: "25%",
          }
        },
        {
          breakpoint: 768,
          settings: {
            centerPadding: "20%",
          }
        },
        {
          breakpoint: 576,
          settings: {
            centerPadding: "15%",
          }
        },
        {
          breakpoint: 420,
          settings: {
            centerPadding: "10%",
          }
        }
      ]
  };
  return (
    <>
        <div data-aos="fade-up" className="arrowtwo">
            <Slider className={`mt-4`} {...educationslider}>

            <div className="educationslider-card-outer">
                <div className="educationslider-card-item">
                    <div className="eci-img-bx">
                        <img src={hqdefault} alt="" />
                    </div>
                    <div className="eci-data-bx">
                        <div className="eci-heading">Beginner's Guide</div>
                        <div className="eci-text">Start your trading journey on solid ground with our Beginner's Guide. From understanding market fundamentals to navigating our platform, this section lays the foundation for your success.</div>
                    </div>
                </div>
            </div>

            <div className="educationslider-card-outer">
                <div className="educationslider-card-item">
                    <div className="eci-img-bx">
                        <img src={hqdefault} alt="" />
                    </div>
                    <div className="eci-data-bx">
                        <div className="eci-heading">Beginner's Guide</div>
                        <div className="eci-text">Start your trading journey on solid ground with our Beginner's Guide. From understanding market fundamentals to navigating our platform, this section lays the foundation for your success.</div>
                    </div>
                </div>
            </div>

            <div className="educationslider-card-outer">
                <div className="educationslider-card-item">
                    <div className="eci-img-bx">
                        <img src={hqdefault} alt="" />
                    </div>
                    <div className="eci-data-bx">
                        <div className="eci-heading">Beginner's Guide</div>
                        <div className="eci-text">Start your trading journey on solid ground with our Beginner's Guide. From understanding market fundamentals to navigating our platform, this section lays the foundation for your success.</div>
                    </div>
                </div>
            </div>

            </Slider>
        </div>
    </>
  );
}
