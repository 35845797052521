import React from 'react';
import {Link} from "react-router-dom";
import CommonHelmet from '../../components/CommonHelmet';

// Design Files
// import { useMenu } from '../../components/Header/MenuContext'

// Images Common File
import * as Img from '../../components/Img';
import grap from '../../img/icons/grap.svg';
import cdzgvbsxrfhdtgf from '../../img/cdzgvbsxrfhdtgf.png';
import vadzgbstgy from '../../img/vadzgbstgy.png';

import trade1 from '../../img/trade/trade(1).svg'
import trade2 from '../../img/trade/trade(2).svg'
import trade3 from '../../img/trade/trade(3).svg'
import trade4 from '../../img/trade/trade(4).svg'
import IndicesSlider from '../Sliders/IndicesSlider';


export default function PageIndices() {

  // const { handleScreenChange } = useMenu();

  const questions = [
    {
      question: "DISCOVER HOW TO TRADE?",
      answer: (
        <>
          <p>Download our Education app to learn how to trade on WinproFX Trader's various financial instruments. To get started, we've covered every aspect of trading.</p>

          <ul>
            <li>A Brief Summary of Recent News and Opinions</li>
            <li>Volatility Forecast Based on Technical Analysis</li>
            <li>Brief overview of major currency pairs based on the provided fundamental</li>
          </ul>

          <p>You can predict on the price of indices rising or falling without taking ownership of the underlying asset with CFDs. Indices are a highly liquid market to trade, and with more trading hours than most other markets, you can receive longer exposure to potential opportunities.</p>
        </>
      ),
    },
    {
      question: "HOW ARE STOCK MARKET INDICES CALCULATED?",
      answer: (
        <>
          <p>Most stock market indices are calculated according to the market capitalisation of their component companies. This method gives greater weighting to larger cap companies, which means their performance will affect an index’s value more than lower cap companies.</p>
        </>
      ),
    },
    {
      question: "TRADE WITH LEVERAGE",
      answer: (
        <>
          <p>When trading with leverage, you should remember that your profit or loss is calculated using the entire position size, not just the initial margin used to open it. This means that while leverage can magnify profits, it can also amplify losses.</p>
        </>
      ),
    }
  ];

  return (
    <>
      <CommonHelmet 
        title="Best Market Indices Platform with Stock Indices"
        description="Trade indices to gain exposure to top markets and sectors in one position. Explore our Best Stock Indices Platform for efficient and comprehensive trading options today"
        keywords="Stock Indices Trading, Best Indices Trading Platform, Trade Stock Market Indices, Global Market Indices, Stock Index Trading Online, Best Stock Indices Platform, Index Trading for Beginners, Efficient Indices Trading Platform, Best Forex Trading Broker Platform, Best Forex Market Trading Platform, Best Online Trading Platform For Professional Traders In India, Best Forex Market Trading Platforms For Advanced Traders, Best Forex Trading Platform For India Beginners, Best Trading Brokers For Beginners, Best Crypto Currency Trading Platform, Best Free Funded Forex Account Without Deposit, Forex Broker 0 Spread, Free Funded Account, Free Funded Accounts, Best Forex Trading Platform"
      />
      <div className="d-none" style={{display:"none"}}>
        <h1>Best Market Indices Platform with Global Market</h1>
        <h2>Trade indices to gain exposure to top markets and sectors in one position. Explore our Best Stock Indices Platform for efficient and comprehensive trading options today</h2>
        <b>Best Market Indices</b>
        <b>Indices Platform</b>
        <b>Global Market</b>
        <b>Best Forex Trading</b>
        <img alt='Best Forex Trading Broker Platform, Best Forex Market Trading Platform, Best Online Trading Platform For Professional Traders In India, Best Forex Market Trading Platforms For Advanced Traders, Best Forex Trading Platform For India Beginners, Best Trading Brokers For Beginners, Best Crypto Currency Trading Platform, Best Free Funded Forex Account Without Deposit, Forex Broker 0 Spread, Free Funded Account, Free Funded Accounts, Best Forex Trading Platform' />
      </div>

      <section className="relative maxwidth1920">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx">

            <div className="breadcrumb-bx mt-5 mt-sm-0">
                <Link className="breadcrumb-link" to="/">Home</Link>
                <div className="breadcrumb-link breadcrumb-active">/ Markets / Indices</div>
            </div>
            <div data-aos="fade-up" className="tlm-heading">WHAT ARE <br/> <span className="tlm-h-color">INDICES</span>?</div>
            <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">Indices are a measurement of the price performance of a group of shares from an exchange. For example, the FTSE 100 tracks the 100 largest companies on the London Stock Exchange (LSE). Trading indices enables you to get exposure to an entire economy or sector at once, while only having to open a single position. For optimal trading explore our Best Stock Indices Platform for comprehensive and efficient trading options.</div>
            <div data-aos="fade-up" data-aos-delay="500" className="tlm-btns">
                {/* <div data-bs-target="#SignUpBtns" data-bs-toggle="modal" className="tlm-btn-items tbi-fill"><span>Open Account</span></div> */}
                <a href="https://my.winprofx.com/en/register" target='_blank' rel="noreferrer" className="tlm-btn-items tbi-fill"><span>Open Account</span></a>
                {/* <div onClick={() => handleScreenChange('register-screen')} className="tlm-btn-items tbi-fill"><span>Open Account</span></div> */}
                {/* <div className="tlm-btn-items tbi-none">Try Demo <img src={Img.arrowright} alt="" /> </div> */}
            </div>
          </div>
        </div>
        <div className="textlayout-bg-bx aefiuhgaevse">
          <img className="d-none d-sm-block" src={cdzgvbsxrfhdtgf} alt="" />
          <img className="d-block d-sm-none" src={vadzgbstgy} alt="" />
        </div> 
      </section>

      <div className="py-md-3 py-2"></div>

      <section className="relative overflowhidden">
        <div className="container-lg cl-custome px-0 px-md-3">
          <div className="common-section">

            <div className="d-none d-md-flex row justify-content-center mt-4">

              <div data-aos="fade-right" className="col-lg-5 col-md-6">
                <div className="trades-card-item">
                  <div className="tci-textdata">

                    <div className="tci-td-inner">
                      <img className="tci-data-img" src={trade1} alt="UK 100" />
                      <div className="tci-data-name">UK 100<span>FTSE 100 Index</span></div>
                    </div>

                    <div className="tci-td-inner tci-flex-column">
                      <div className="tci-rate"><span>USD</span>1.0932</div>
                      <div className="tci-status tci-red">−0.00072 −0.07%</div>
                    </div>

                  </div>

                  <div className="tci-grap-bx">
                    <img src={grap} alt="" />
                  </div>

                  <div className="tci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

                </div>
              </div>

              <div  data-aos="fade-left" className="col-lg-5 col-md-6">
                <div className="trades-card-item">
                  <div className="tci-textdata">

                    <div className="tci-td-inner">
                      <img className="tci-data-img" src={trade2} alt="DJI" />
                      <div className="tci-data-name">DJI<span>Dow Jones Industrial</span></div>
                    </div>

                    <div className="tci-td-inner tci-flex-column">
                      <div className="tci-rate"><span>USD</span>1.0932</div>
                      <div className="tci-status tci-green">+0.00072 +0.07%</div>
                    </div>

                  </div>

                  <div className="tci-grap-bx">
                    <img src={grap} alt="" />
                  </div>

                  <div className="tci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

                </div>
              </div>

              <div data-aos="fade-right" className="col-lg-5 col-md-6">
                <div className="trades-card-item">
                  <div className="tci-textdata">

                    <div className="tci-td-inner">
                      <img className="tci-data-img" src={trade3} alt="Nasdaq 100 Index" />
                      <div className="tci-data-name">Nasdaq 100 Index<span>NASDAQ, INC.</span></div>
                    </div>

                    <div className="tci-td-inner tci-flex-column">
                      <div className="tci-rate"><span>USD</span>1.0932</div>
                      <div className="tci-status tci-green">+0.00072 +0.07%</div>
                    </div>

                  </div>

                  <div className="tci-grap-bx">
                    <img src={grap} alt="" />
                  </div>

                  <div className="tci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

                </div>
              </div>

              <div  data-aos="fade-left" className="col-lg-5 col-md-6">
                <div className="trades-card-item">
                  <div className="tci-textdata">

                    <div className="tci-td-inner">
                      <img className="tci-data-img" src={trade4} alt="US500" />
                      <div className="tci-data-name">US500<span>US500</span></div>
                    </div>

                    <div className="tci-td-inner tci-flex-column">
                      <div className="tci-rate"><span>USD</span>1.0932</div>
                      <div className="tci-status tci-red">−0.00072 −0.07%</div>
                    </div>

                  </div>

                  <div className="tci-grap-bx">
                    <img src={grap} alt="" />
                  </div>

                  <div className="tci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

                </div>
              </div>

            </div>

            <div className="d-block d-md-none mt-5">
              <IndicesSlider />
            </div>
          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="common-section">
            <div id="faqsaccordion" className="faqs-accordion mt-4"> {/*  data-bs-parent="#faqsaccordion" */}
              {questions.map((item, index) => (
              <div data-aos="fade-up" className="acc-item" key={index}>
                  <div className="acc-heding collapsed" id={`heading${index}`} data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                    {item.question}
                    <span className="down-icon">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                      </svg> */}
                    </span>
                  </div>

                  <div id={`collapse${index}`} className="collapse" aria-labelledby={`heading${index}`}>
                      <div className="faqs-data">
                        {item.answer}
                      </div>
                  </div>
              </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx py-0">
            <div data-aos="fade-up" className="tlm-heading heading-24">Critical Insights: Unveiling the <span className="tlm-h-color">Pinnacle Learnings</span> for <br/> Strategic Success.</div>
            <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">Beginners and experienced forex traders alike must keep in mind that practice, knowledge, and discipline are key to getting and staying ahead. The Best Market Indices Platform can help enhance your trading success.</div>
            <div data-aos="fade-up" data-aos-delay="500" className="tlm-btns">
            <a href='https://api.whatsapp.com/send?phone=+971562520783&text=Hello' className="tlm-btn-items tbi-fill"><span><img className='whatappimg' src={Img.whatsapp} alt="" />WhatsApp</span></a>
            </div> 
          </div>
        </div>
      </section>
    </>
  );
};
