import React from 'react'
import { Link } from 'react-router-dom'

import notfoundpage from "../../img/icons/not-found-page.png"

export default function Page404() {
  return (
   <div className="web-status-bx">
        <img className="web-img" src={notfoundpage} alt="" />
        {/* <div className="web-number">404</div> */}
        <div className="web-heading">Page Not Found</div>
        <div className="web-text">Sorry, but the page you are looking for is not found.  Please, make sure you have typed the correct URL</div>
        <Link to="/"  className="common-btn-item cbi-fill"><span>Back To Home</span></Link>
   </div>
  )
}
