import React from 'react';
import {Link, NavLink} from "react-router-dom";
import { LiveChatWidget } from '@livechat/widget-react';
// EventHandlerPayload

// Css Files
import '../../css/footer.css'

// Images Common Files
import * as Img from '../Img';
// import flag1 from "../../img/flag/flag(1).jpg";
// import flag2 from "../../img/flag/flag(2).jpg";
// import flag3 from "../../img/flag/flag(3).jpg";
import flag4 from "../../img/flag/flag(4).jpg";

// Design Files
import Socialicon from '../Socialicon';

export default function Footer({hideSection}) {

    const footercontactinfo = [
        {
            contactClassName: "col-md-12 col-sm-12 whatsapp-link",
            contactName: "whatsapp",
            contactImg: Img.whatsapp,
            contactData: "whatsapp",
            contactLink: "https://api.whatsapp.com/send?phone=+971562520783&text=Hello",
        },
        {
            contactClassName: "col-md-12 col-sm-12",
            contactName: "Email Us",
            contactImg: Img.sms,
            contactData: "support@winprofx.com",
            contactLink: "mailto:support@winprofx.com",
        },
        {
            contactClassName: "col-md-12 col-sm-6",
            contactName: "Call Us",
            contactImg: Img.call,
            contactData: "+971 4 447 1894",
            contactLink: "tel:+97144471894",
        },
        // {
        //     contactClassName: "col-12",
        //     contactName: "Address",
        //     contactImg: Img.location,
        //     // contactData: "1st Floor  The Sotheby Building Rodney Bay Gros-Islet,SAINT Lucia P.O Box 838 Castries Saint Lucia.",
        //     contactData: "P.O Box 838 Castries, Saint Lucia.",
        //     contactLink: "/",
        // },
    ]

    const AddressInfo = [
        {
            contactClassName: "col-12 mb-2",
            contactName: "Registered Address:",
            contactImg: flag4,
            contactData: "1st Floor, The Sotheby Building, Rodney Bay, Gros-Islet, SAINT Lucia P.O Box 838, Castries, Saint Lucia.",
            contactLink: "",
        },

        {
            contactClassName: "col-12 mb-2",
            contactName: "Registration Number:",
            // contactImg: Img.location,
            contactData: "2023-00197",
            contactLink: "",
        },

        // {
        //     contactClassName: "col-12",
        //     contactName: "Physical Address:",
        //     contactImg: flag1,
        //     contactData: "Office No. 1614 , Churchill Tower ,Marasi Dr, Business Bay - Dubai",
        //     contactLink: "",
        // },
    ]

    return (
    <>
        {hideSection && <div className="pb-5"></div> }
        
        <footer className="main-footer pt-5 pb-1">
            <div className="container-lg cl-custome">
                {hideSection &&
                    <>
                    <div className="row justify-content-lg-between justify-content-sm-around">

                        <div data-aos="fade-up" className="col-xl-3 col-lg-3 col-12 mb-lg-0 mb-4">

                            <div className="text-lg-start text-center">
                                <Link className="footer-logo" to="/">
                                    <img src={Img.logo_light} alt="" />
                                </Link>
                                <div className="footer-sub-heading">Engage in trading with WinproFX – a single platform offering multiple opportunities.</div>
                            </div>

                        </div>

                        <div data-aos="fade-up" className='col-xl-4 col-lg-4 col-md-5 col-sm-12 mb-md-0 mb-4'>
                            <div className="row">
                                <div className='col-md-7 mb-md-0 mb-4'>
                                    <div className="footer-heading">Explore</div>
                                    <div className="footer-links-bx">
                                        <NavLink to="/" className="footer-link">Home</NavLink>
                                        <NavLink to="/platform" className="footer-link">Platform</NavLink>
                                        <NavLink to="/about" className="footer-link">About Us</NavLink>
                                        <NavLink to="/introducing-broker" className="footer-link">Our IB</NavLink>
                                        <NavLink to="/free-funded-accounts" className="footer-link">Free Funded Accounts</NavLink>
                                        <NavLink to="/education" className="footer-link">Education</NavLink>
                                        {/* <div data-bs-target="#GlobalRegulationsModal" data-bs-toggle="modal" className="footer-link">Global Regulations</div> */}
                                        <NavLink to="/news" className="footer-link">News & Insights</NavLink>
                                        <NavLink to="/join-our-team" className="footer-link">Join Our Team</NavLink>
                                        <NavLink to="/contact" className="footer-link">Contact Us</NavLink>
                                        <NavLink to="/sitemap" className="footer-link">Sitemap</NavLink>
                                    </div>
                                </div>
                                <div className='col-md-5'>
                                    <div className="footer-heading">Markets</div>
                                    <div className="footer-links-bx">
                                        <NavLink to="/forex" className="footer-link">Forex</NavLink>
                                        <NavLink to="/indices" className="footer-link">Indices</NavLink>
                                        <NavLink to="/crypto" className="footer-link">Crypto</NavLink>
                                        <NavLink to="/metals" className="footer-link">Metals</NavLink>
                                        <NavLink to="/commodities" className="footer-link">Commodities</NavLink>
                                        <NavLink to="/oil" className="footer-link">Oil</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div data-aos="fade-up" className='col-xl-5 col-lg-5 col-md-7 col-sm-12 mb-md-0 mb-4'>
                            <div className="row">

                                <div className='col-sm-6 mb-sm-0 mb-4'>
                                    <div className="footer-heading">Contact Info</div>
                                    <div className="row">
                                        { footercontactinfo.map((item, index) => ( 
                                            <div className={item.contactClassName} key={index}>
                                                <Link className="footer-contact-info fci-nowrap" to={item.contactLink}>
                                                    
                                                    <img src={item.contactImg} alt="" />
                                                    
                                                    {item.contactData}
                                                    
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                    <Socialicon className="social-icon justify-content-start mt-3" />
                                </div>

                                <div className='col-sm-6'>
                                    <div className="footer-heading">Address</div>
                                    <div className="row">
                                        { AddressInfo.map((item, index) => ( 
                                            <div className={item.contactClassName} key={index}>
                                                <div className="footer-contact-lable">

                                                    {( item.contactImg && <img src={item.contactImg} alt="" /> )}
                                                    {item.contactName}
                                                </div>
                                                <Link className="footer-contact-info" to={item.contactLink}>
                                                    {item.contactData}
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className="col-12 pt-md-4"></div>

                        <div data-aos="fade-up" className="col-md-6 mt-4">
                            <div className="footer-heading">Risk Warning</div>
                            <div className="footer-sub-heading fsh-t">Please note that forex trading and trading in other leveraged products involves a significant level of risk and is not suitable for all investors. Trading in financial instruments may result in losses as well as profits and your losses can be greater than your initial invested capital. Before undertaking any such transactions, you should ensure that you fully understand the risks involved and seek independent advice if necessary.</div>
                            {/* <div className="footer-heading">Global Regulations</div>
                            <div className="footer-heading fh-t mt-2"> <img src={flag2} alt='' /> </div>
                            <div className="footer-sub-heading fsh-t">Winprofx is authorized and reputed by the Labuan Financial Services Authority Malaysia with reference number LLC46738.</div>

                            <div className="footer-heading fh-t mt-4"> <img src={flag1} alt='' /> </div>
                            <div className="footer-sub-heading fsh-t">Winprofx has registered REP Office in United Arab Emirates with License No 1199826, reputed by Dubai Economic Department.</div>

                            <div className="footer-heading fh-t mt-4"> <img src={flag3} alt='' /> </div>
                            <div className="footer-sub-heading fsh-t">Winprofx in Australia with ACN 650 910 384 is registered with Australian Securities Investment Commission.</div>

                            <div className="footer-heading fh-t mt-4"> <img src={flag4} alt='' /> </div>
                            <div className="footer-sub-heading fsh-t">Winprofx is authorized and reputed by the St. Lucia Authorities with reference number No. 00197.</div> */}
                        </div>

                        <div data-aos="fade-up" className="col-md-6 mt-4">
                            {/* <div className="footer-heading">Risk Warning</div>
                            <div className="footer-sub-heading fsh-t">Please note that forex trading and trading in other leveraged products involves a significant level of risk and is not suitable for all investors. Trading in financial instruments may result in losses as well as profits and your losses can be greater than your initial invested capital. Before undertaking any such transactions, you should ensure that you fully understand the risks involved and seek independent advice if necessary.</div> */}
                            
                            <div className="footer-heading">Restricted Regions</div>
                            {/* <div className="footer-heading mt-4">Restricted Regions</div> */}
                            <div className="footer-sub-heading fsh-t">WinproFX Limited does not provide services for citizens/residents of the USA, Cuba, Iraq, Myanmar, North Korea, Sudan. The services of WinproFX Limited are not intended for distribution to, or use by, any person in any country or jurisdiction where such distribution or use would be contrary to local law or regulation.</div>
                        </div>

                    </div> 

                    <div className="footer-bottom-link">
                        <div className="footer-links-bx">
                            <NavLink to="/privacy-policy" className="footer-link">Privacy Policy</NavLink>
                            <NavLink to="/terms-and-conditions" className="footer-link">Terms & Conditions</NavLink>
                            <NavLink to="/aml-policy" className="footer-link">AML Policy</NavLink>
                            <NavLink to="/deposit-withdrawal-policy" className="footer-link">Deposit & withdrawal Policy</NavLink>
                            <NavLink to="/restricted-countries" className="footer-link">Restricted Countries</NavLink>
                            <NavLink to="/risk-disclosure" className="footer-link">Risk Disclosure</NavLink>
                        </div>
                    </div>
                    </>
                }
                <div className={`footer-bottom ${hideSection ? "": "justify-content-center"} `}>
                    <div className="copyright">© 2024 All Rights Reserved By WinproFx</div>
                    {/* <div className="mede-with">Made with
                        <span className="icons-box-made">
                            <div className="fa fa-heart-o" aria-hidden="true"></div>
                            <div className="fa fa-heart" aria-hidden="true"></div>
                        </span>
                        by: <a href="https://pmcommu.com/" target="_blank" rel="noreferrer">PM Communications</a>
                    </div> */}
                </div>
            </div>
        </footer>

        {/* Global Regulations Modal Popup */}
        {hideSection &&
            <>
            <div className="modal fade zoom" id="GlobalRegulationsModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content custom-content">
                        <div className="custom-modal-header">
                            <div className="cmh-lable">Global Regulations</div>

                            <span className="close-icon" data-bs-dismiss="modal" aria-label="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                                </svg>
                            </span>
                        </div>
                        <div className="custom-modal-data px-3 pb-3">
                            {/* <div className="footer-heading fh-t mt-2"> <img src={flag2} alt='' /> </div>
                            <div className="footer-sub-heading fsh-t">Winprofx is authorized and reputed by the Labuan Financial Services Authority Malaysia with reference number LLC46738.</div>

                            <div className="footer-heading fh-t mt-4"> <img src={flag1} alt='' /> </div>
                            <div className="footer-sub-heading fsh-t">Winprofx has registered REP Office in United Arab Emirates with License No 1199826, reputed by Dubai Economic Department.</div> */}

                            {/* <div className="footer-heading fh-t mt-4"> <img src={flag3} alt='' /> </div>
                            <div className="footer-sub-heading fsh-t">Winprofx in Australia with ACN 650 910 384 is registered with Australian Securities Investment Commission.</div> */}

                            <div className="footer-heading fh-t mt-2"> <img src={flag4} alt='' /> </div>
                            <div className="footer-sub-heading fsh-t">Winprofx is authorized and reputed by the St. Lucia Authorities with reference number No. 00197.</div>
                        </div>
                    </div>
                </div>
            </div>

            <LiveChatWidget license="16090353"/>
            </>
        }            

    </>
    );
}