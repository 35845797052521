import React from 'react'
import { Link } from 'react-router-dom'
import checkmark from '../../img/icons/check.png';

export default function PageThankYou() {
  return (
    <div className="web-thankyou-bx">
         <img className="web-img" src={checkmark} alt=""/>
        <div className="web-heading">Thank You</div>
        <div className="web-text">Thank you for choosing WinproFx ! Our team will reach out to you shortly.</div>
        <Link to="/"  className="common-btn-item cbi-fill"><span>Back To Home</span></Link>
    </div>
  )
}
