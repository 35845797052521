import React from 'react';
import {Link} from "react-router-dom";
import CommonHelmet from '../../components/CommonHelmet';

// Design Files
// import { useMenu } from '../../components/Header/MenuContext'

// Images Common File
import * as Img from '../../components/Img';
import grap from '../../img/icons/grap.svg';
import sfvasdzgvszrg from '../../img/sfvasdzgvszrg.png'
import cdzgvbscxsdgvszdxrfhdtgf from '../../img/cdzgvbscxsdgvszdxrfhdtgf.png'

import trade9 from '../../img/trade/trade(9).svg'
import trade11 from '../../img/trade/trade(11).svg'
import trade12 from '../../img/trade/trade(12).svg'
import trade13 from '../../img/trade/trade(13).svg'
import CommoditiesSlider from '../Sliders/CommoditiesSlider';


export default function PageCommodities() {

  // const { handleScreenChange } = useMenu();

  const questions = [
    {
      question: "What are commodities?",
      answer: (
        <>
          <p>Commodities are raw materials or primary agricultural products that can be bought and sold in the financial markets. They are typically used in the production of other goods and services and are traded on commodity exchanges around the world. Some examples include metals (gold, silver, and platinum), energy sources (coal, crude oil, and natural gas), agricultural products (soybeans, corn, and wheat), and livestock and animal products (cattle, eggs, and milk).</p>
        </>
      ),
    },
    {
      question: "How does commodity trading work?",
      answer: (
        <>
          <p>Commodities are raw materials or primary agricultural products that can be bought and sold in the financial markets. They are typically used in the production of other goods and services and are traded on commodity exchanges around the world. Some examples include metals (gold, silver, and platinum), energy sources (coal, crude oil, and natural gas), agricultural products (soybeans, corn, and wheat), and livestock and animal products (cattle, eggs, and milk).</p>
        </>
      ),
    },
    {
      question: "How much money do I need to start commodity trading?",
      answer: (
        <>
          <p>Commodities are raw materials or primary agricultural products that can be bought and sold in the financial markets. They are typically used in the production of other goods and services and are traded on commodity exchanges around the world. Some examples include metals (gold, silver, and platinum), energy sources (coal, crude oil, and natural gas), agricultural products (soybeans, corn, and wheat), and livestock and animal products (cattle, eggs, and milk).</p>
        </>
      ),
    }
  ];

  return (
    <>
      <CommonHelmet 
        title="Best commodity market Platform Advanced Tools and Insights"
        description="Trade CFDs on gold, oil, and coffee with WinproFx. Enjoy zero commissions and no hidden fees. Explore our top commodity trading platform for a seamless experience today."
        keywords="Commodities Trading, CFD Commodities, Spot Commodities, Futures Trading, Gold Trading, Oil Trading, Coffee Trading, Commodity Market Instruments, Best Commodity Trading Platform, Zero Commission Trading, Best Forex Trading Broker Platform, Best Forex Market Trading Platform, Best Online Trading Platform For Professional Traders In India, Best Forex Market Trading Platforms For Advanced Traders, Best Forex Trading Platform For India Beginners, Best Trading Brokers For Beginners, Best Crypto Currency Trading Platform, Best Free Funded Forex Account Without Deposit, Forex Broker 0 Spread, Free Funded Account, Free Funded Accounts, Best Forex Trading Platform"
      />

      <div className="d-none" style={{display:"none"}}>
        <h1>Best commodity market Platform Advanced Tools and Insights</h1>
        <h2>Trade CFDs on gold, oil, and coffee with winprofx. Enjoy zero commissions and no hidden fees. Explore our top commodity trading platform for a seamless experience today.</h2>
        <b>Commodities Trading, CFD Commodities, Spot Commodities, Futures Trading, Gold Trading, Oil Trading, Coffee Trading, Commodity Market Instruments, Best Commodity Trading Platform, Zero Commission Trading</b>
        <img src="" alt="Best Forex Trading Broker Platform, Best Forex Market Trading Platform, Best Online Trading Platform For Professional Traders In India, Best Forex Market Trading Platforms For Advanced Traders, Best Forex Trading Platform For India Beginners, Best Trading Brokers For Beginners, Best Crypto Currency Trading Platform, Best Free Funded Forex Account Without Deposit, Forex Broker 0 Spread, Free Funded Account, Free Funded Accounts, Best Forex Trading Platform" />
      </div>

      <section className="relative maxwidth1920">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx">
            <div className="breadcrumb-bx mt-4 mt-sm-0">
                <Link className="breadcrumb-link" to="/">Home</Link>
                <div className="breadcrumb-link breadcrumb-active">/ Markets / Commodities</div>
            </div>
            <div data-aos="fade-up" className="tlm-heading">Invest in the <span className="tlm-h-color">metals, energy <br/> and agriculture</span> markets</div>
            <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">Take advantage of market volatility and choose from CFDs on spot commodities and futures. At WINPROFX, we offer some of the most popular instruments in the commodity market including gold, oil, and coffee, for speculation and hedging. Trade per whole tick movement with zero commissions and no hidden fees. Explore our Best Commodity Trading Platform for seamless trading experiences.</div>
            <div data-aos="fade-up" data-aos-delay="500" className="tlm-btns">
                {/* <div data-bs-target="#SignUpBtns" data-bs-toggle="modal" className="tlm-btn-items tbi-fill"><span>Open Account</span></div> */}
                <a href="https://my.winprofx.com/en/register" target='_blank' rel="noreferrer" className="tlm-btn-items tbi-fill"><span>Open Account</span></a>
                {/* <div onClick={() => handleScreenChange('register-screen')} className="tlm-btn-items tbi-fill"><span>Open Account</span></div> */}
                {/* <div className="tlm-btn-items tbi-none">Try Demo <img src={Img.arrowright} alt="" /> </div> */}
            </div>
          </div>
        </div>
        <div className="textlayout-bg-bx aefiuhgaevse affter-none">
          <img className='m-0 d-none d-lg-block' src={sfvasdzgvszrg} alt="" />
          <img className='m-0 d-block d-lg-none' src={cdzgvbscxsdgvszdxrfhdtgf} alt="" />
        </div> 
      </section>

      <div className="py-3"></div>

      <section className="relative overflowhidden">
        <div className="container-lg cl-custome px-0 px-md-3">
          <div className="common-section">
            <div className="d-none d-md-flex row justify-content-center mt-4">

              <div data-aos="fade-right" className="col-lg-5 col-md-6">
                <div className="trades-card-item">
                  <div className="tci-textdata">

                    <div className="tci-td-inner">
                      <img className="tci-data-img" src={trade11} alt="Gold" />
                      <div className="tci-data-name">Gold<span>Gold Futures</span></div>
                    </div>

                    <div className="tci-td-inner tci-flex-column">
                      <div className="tci-rate"><span>USD</span>1.0932</div>
                      <div className="tci-status tci-red">−0.00072 −0.07%</div>
                    </div>

                  </div>

                  <div className="tci-grap-bx">
                    <img src={grap} alt="" />
                  </div>

                  <div className="tci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

                </div>
              </div>

              <div  data-aos="fade-left" className="col-lg-5 col-md-6">
                <div className="trades-card-item">
                  <div className="tci-textdata">

                    <div className="tci-td-inner">
                      <img className="tci-data-img" src={trade12} alt="COCOA FUTURES" />
                      <div className="tci-data-name">COCOA FUTURES<span>COCOA</span></div>
                    </div>

                    <div className="tci-td-inner tci-flex-column">
                      <div className="tci-rate"><span>USD</span>1.0932</div>
                      <div className="tci-status tci-green">+0.00072 +0.07%</div>
                    </div>

                  </div>

                  <div className="tci-grap-bx">
                    <img src={grap} alt="" />
                  </div>

                  <div className="tci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

                </div>
              </div>

              <div data-aos="fade-right" className="col-lg-5 col-md-6">
                <div className="trades-card-item">
                  <div className="tci-textdata">

                    <div className="tci-td-inner">
                      <img className="tci-data-img" src={trade9} alt="CFDs on Silver" />
                      <div className="tci-data-name">CFDs on Silver<span>Silver</span></div>
                    </div>

                    <div className="tci-td-inner tci-flex-column">
                      <div className="tci-rate"><span>USD</span>1.0932</div>
                      <div className="tci-status tci-green">+0.00072 +0.07%</div>
                    </div>

                  </div>

                  <div className="tci-grap-bx">
                    <img src={grap} alt="" />
                  </div>

                  <div className="tci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

                </div>
              </div>

              <div  data-aos="fade-left" className="col-lg-5 col-md-6">
                <div className="trades-card-item">
                  <div className="tci-textdata">

                    <div className="tci-td-inner">
                      <img className="tci-data-img" src={trade13} alt="Corn Futures" />
                      <div className="tci-data-name">Corn Futures<span>Corn</span></div>
                    </div>

                    <div className="tci-td-inner tci-flex-column">
                      <div className="tci-rate"><span>USD</span>1.0932</div>
                      <div className="tci-status tci-red">−0.00072 −0.07%</div>
                    </div>

                  </div>

                  <div className="tci-grap-bx">
                    <img src={grap} alt="" />
                  </div>

                  <div className="tci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

                </div>
              </div>

            </div>
            <div className="d-block d-md-none">
              <CommoditiesSlider />
            </div>
          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="common-section">
            <div id="faqsaccordion" className="faqs-accordion mt-4"> {/*  data-bs-parent="#faqsaccordion" */}
              {questions.map((item, index) => (
              <div data-aos="fade-up" className="acc-item" key={index}>
                  <div className="acc-heding collapsed" id={`heading${index}`} data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                    {item.question}
                    <span className="down-icon">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                      </svg> */}
                    </span>
                  </div>

                  <div id={`collapse${index}`} className="collapse" aria-labelledby={`heading${index}`}>
                      <div className="faqs-data">
                        {item.answer}
                      </div>
                  </div>
              </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx py-0">
            <div data-aos="fade-up" className="tlm-heading heading-24">Critical Insights: Unveiling the <span className="tlm-h-color">Pinnacle Learnings</span> for <br/> Strategic Success.</div>
            <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">Beginners and experienced forex traders alike must keep in mind that practice, knowledge, and discipline are key to getting and staying ahead. Utilizing the Best Commodity Market Platform enhances trading strategies.</div>
            <div data-aos="fade-up" data-aos-delay="500" className="tlm-btns">
            <a href='https://api.whatsapp.com/send?phone=+971562520783&text=Hello' className="tlm-btn-items tbi-fill"><span><img className='whatappimg' src={Img.whatsapp} alt="" />WhatsApp</span></a>
            </div> 
          </div>
        </div>
      </section>
    </>
  );
};
