import React from 'react';

// Slider Code
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

// Images Common File
// import * as Img from '../../components/Img';

// Images
import client1 from '../../img/client/Untitled-1.jpg';
import client2 from '../../img/client/Untitled-2.jpg';
import client3 from '../../img/client/Untitled-3.jpg';
import client4 from '../../img/client/Untitled-4.jpg';
import client6 from '../../img/client/Untitled-6.jpg';
import client7 from '../../img/client/Untitled-7.jpg';
import client8 from '../../img/client/Untitled-8.jpg';

export default function NewsInsightsSlider() {
  const newsInsightsSlider = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    swipeToSlide:true,
    // className: "center",
    centerMode: true,
    speed: 500,
    autoplaySpeed: 3000,
    responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            centerPadding: "10%",
          }
        },
        {
          breakpoint: 420,
          settings: {
            slidesToShow: 1,
            centerPadding: "6%",
          }
        }
      ]
  };
  return (
    <>
        <div data-aos="fade-up">
            <Slider className={`mt-4 nci-style-three`} {...newsInsightsSlider}>

            <div className="p-2">
                <a href="https://www.streetinsider.com/Newsfile/WinproFX+Announces+Milestone+in+Commitment+to+Online+Trading+Excellence/22489071.html"  rel="noreferrer" target="_blank" className="news-card-item">
                  <img className="nci-img" src={client1} alt="" />
                </a>
            </div>
            <div className="p-2">
                <a href="https://www.streetinsider.com/Newsfile/WinproFX+Announces+Milestone+in+Commitment+to+Online+Trading+Excellence/22489071.html"  rel="noreferrer" target="_blank" className="news-card-item">
                  <img className="nci-img" src={client2} alt="" />
                </a>
            </div>
            <div className="p-2">
                <a href="https://www.streetinsider.com/Newsfile/WinproFX+Announces+Milestone+in+Commitment+to+Online+Trading+Excellence/22489071.html"  rel="noreferrer" target="_blank" className="news-card-item">
                  <img className="nci-img" src={client3} alt="" />
                </a>
            </div>
            <div className="p-2">
                <a href="https://www.streetinsider.com/Newsfile/WinproFX+Announces+Milestone+in+Commitment+to+Online+Trading+Excellence/22489071.html"  rel="noreferrer" target="_blank" className="news-card-item">
                  <img className="nci-img" src={client4} alt="" />
                </a>
            </div>
            <div className="p-2">
                <a href="https://www.streetinsider.com/Newsfile/WinproFX+Announces+Milestone+in+Commitment+to+Online+Trading+Excellence/22489071.html"  rel="noreferrer" target="_blank" className="news-card-item">
                  <img className="nci-img" src={client6} alt="" />
                </a>
            </div>
            <div className="p-2">
                <a href="https://www.streetinsider.com/Newsfile/WinproFX+Announces+Milestone+in+Commitment+to+Online+Trading+Excellence/22489071.html"  rel="noreferrer" target="_blank" className="news-card-item">
                  <img className="nci-img" src={client7} alt="" />
                </a>
            </div>
            <div className="p-2">
                <a href="https://www.streetinsider.com/Newsfile/WinproFX+Announces+Milestone+in+Commitment+to+Online+Trading+Excellence/22489071.html"  rel="noreferrer" target="_blank" className="news-card-item">
                  <img className="nci-img" src={client8} alt="" />
                </a>
            </div>

            {/* <div className="p-2">
                <a href="https://finance.yahoo.com/news/winprofx-announces-milestone-commitment-online-063100151.html"  rel="noreferrer" target="_blank" className="news-card-item">
                  <div className="nci-tag">yahoo!finance</div>
                  <div className="nci-heading">Winprofx Announces Milestone in Commitment to Online Trading Excellence</div>
                  <div className="nci-read-time">1 minutes to read</div>
                  <div className="nci-text">Dubai, United Arab Emirates--(Newsfile Corp. - December 5, 2023) - WinproFX, an established online trading platform with over three decades of industry presence, announces a significant corporate milestone.</div>
                  <div className="nci-readmore">Read<img src={Img.arrowright} alt="" /></div>
                  <div className="nci-date">December 5, 2023</div>
                </a>
            </div>

            <div className="p-2">
                <a href="https://www.streetinsider.com/Newsfile/WinproFX+Announces+Milestone+in+Commitment+to+Online+Trading+Excellence/22489071.html"  rel="noreferrer" target="_blank" className="news-card-item">
                  <div className="nci-tag">Street Insider</div>
                  <div className="nci-heading">WinproFX Announces Milestone in Commitment to Online Trading Excellence</div>
                  <div className="nci-read-time">1 minutes to read</div>
                  <div className="nci-text">Dubai, United Arab Emirates--(Newsfile Corp. - December 5, 2023) - WinproFX, an established online trading platform with over three decades of industry presence, announces a significant corporate milestone.</div>
                  <div className="nci-readmore">Read<img src={Img.arrowright} alt="" /></div>
                  <div className="nci-date">December 5, 2023</div>
                </a>
            </div>

            <div className="p-2">
                <a href="https://www.digitaljournal.com/pr/news/newsfile/winprofx-announces-milestone-in-commitment-to-online-trading-excellence"  rel="noreferrer" target="_blank" className="news-card-item">
                  <div className="nci-tag">Digital Journal</div>
                  <div className="nci-heading">WinproFX Announces Milestone in Commitment to Online Trading Excellence</div>
                  <div className="nci-read-time">1 minutes to read</div>
                  <div className="nci-text">Dubai, United Arab Emirates--(Newsfile Corp. - December 5, 2023) - WinproFX, an established online trading platform with over three decades of industry presence, announces a significant corporate milestone.</div>
                  <div className="nci-readmore">Read<img src={Img.arrowright} alt="" /></div>
                  <div className="nci-date">December 5, 2023</div>
                </a>
            </div>

            <div className="p-2">
                <a href="https://www.thewhig.com/newsfile/189715-winprofx-announces-milestone-in-commitment-to-online-trading-excellence"  rel="noreferrer" target="_blank" className="news-card-item">
                  <div className="nci-tag">Thewhig</div>
                  <div className="nci-heading">WinproFX Announces Milestone in Commitment to Online Trading Excellence</div>
                  <div className="nci-read-time">1 minutes to read</div>
                  <div className="nci-text">Dubai, United Arab Emirates--(Newsfile Corp. - December 5, 2023) - WinproFX, an established online trading platform with over three decades of industry presence, announces a significant corporate milestone.</div>
                  <div className="nci-readmore">Read<img src={Img.arrowright} alt="" /></div>
                  <div className="nci-date">December 5, 2023</div>
                </a>
            </div> */}

            {/* <div className="p-2">
                <a href="https://ceo.ca/@newsfile/winprofx-announces-milestone-in-commitment-to-online"  rel="noreferrer" target="_blank" className="news-card-item">
                  <div className="nci-tag">Ceo.ca</div>
                  <div className="nci-heading">WinproFX Announces Milestone in Commitment to Online Trading Excellence</div>
                  <div className="nci-read-time">1 minutes to read</div>
                  <div className="nci-text">Dubai, United Arab Emirates--(Newsfile Corp. - December 5, 2023) - WinproFX, an established online trading platform with over three decades of industry presence, announces a significant corporate milestone.</div>
                  <div className="nci-readmore">Read<img src={Img.arrowright} alt="" /></div>
                  <div className="nci-date">December 5, 2023</div>
                </a>
            </div>

            <div className="p-2">
                <a href="https://www.theglobeandmail.com/investing/markets/markets-news/Newsfile/22488517/winprofx-announces-milestone-in-commitment-to-online-trading-excellence/"  rel="noreferrer" target="_blank" className="news-card-item">
                  <div className="nci-tag">The Globe And Mail</div>
                  <div className="nci-heading">WinproFX Announces Milestone in Commitment to Online Trading Excellence</div>
                  <div className="nci-read-time">1 minutes to read</div>
                  <div className="nci-text">Dubai, United Arab Emirates--(Newsfile Corp. - December 5, 2023) - WinproFX, an established online trading platform with over three decades of industry presence, announces a significant corporate milestone.</div>
                  <div className="nci-readmore">Read<img src={Img.arrowright} alt="" /></div>
                  <div className="nci-date">December 5, 2023</div>
                </a>
            </div>

            <div className="p-2">
                <a href="https://huffmag.com/winprofx-the-epitome-of-excellence-in-online-trading/"  rel="noreferrer" target="_blank" className="news-card-item">
                  <div className="nci-tag">Huffmag</div>
                  <div className="nci-heading">WinproFX: The Epitome of Excellence in Online Trading</div>
                  <div className="nci-read-time">2 minutes to read</div>
                  <div className="nci-text">WinproFX, a leading force in the online trading world with a legacy spanning over three decades, proudly announces its unwavering commitment to providing unparalleled trading experiences.</div>
                  <div className="nci-readmore">Read<img src={Img.arrowright} alt="" /></div>
                  <div className="nci-date">January 3, 2024 </div>
                </a>
            </div>

            <div className="p-2">
                <a href="https://www.benzinga.com/pressreleases/24/01/36472404/winprofx-the-epitome-of-excellence-in-online-trading"  rel="noreferrer" target="_blank" className="news-card-item">
                  <div className="nci-tag">Benzinga</div>
                  <div className="nci-heading">WinproFX: The Epitome of Excellence in Online Trading</div>
                  <div className="nci-read-time">2 minutes to read</div>
                  <div className="nci-text">WinproFX, a leading force in the online trading world with a legacy spanning over three decades, proudly announces its unwavering commitment to providing unparalleled trading experiences.</div>
                  <div className="nci-readmore">Read<img src={Img.arrowright} alt="" /></div>
                  <div className="nci-date">January 3, 2024 </div>
                </a>
            </div> */}

            </Slider>
        </div>
    </>
  );
}
