import React from 'react';
import {Link} from "react-router-dom";

// Slider Code
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

// Images Common File
import Component21 from '../../img/icons/layer/Component2(1).svg'
import Component22 from '../../img/icons/layer/Component2(2).svg'
import Component23 from '../../img/icons/layer/Component2(3).svg'
import Component24 from '../../img/icons/layer/Component2(4).svg'
import Component25 from '../../img/icons/layer/Component2(5).svg'

export default function Tradeslider() {
  const tradeslider = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    swipeToSlide:true,
    speed: 500,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "6%",
        }
      }
    ]
  };
  return (
    <>
      <div data-aos="fade-up">
        <Slider className={`slick-hover mt-4`} {...tradeslider}>
          
          <Link to="/forex" className="card-item-showtext">
            <div className="card-item">
                <img className="card-i-img" src={Component21} alt=""/>
                <div className="card-i-heading">Forex</div>
                <div className="card-i-text">Forex or the Foreign Exchange is a global market where the world’s currencies trade. It vastly overshadows the stock market, as forex trading measure up to 5 trillion USD a day.</div>
                <div className="card-i-link">Learn More</div>
            </div>
          </Link>

          <Link to="/indices" className="card-item-showtext">
            <div className="card-item">
                <img className="card-i-img" src={Component22} alt=""/>
                <div className="card-i-heading">indices</div>
                <div className="card-i-text">Indices are a measurement of the price performance of a group of shares from an exchange. For example, the FTSE 100 tracks the 100 largest companies on the London Stock Exchange (LSE).</div>
                <div className="card-i-link">Learn More</div>
            </div>
          </Link>

          <Link to="/crypto" className="card-item-showtext">
            <div className="card-item">
                <img className="card-i-img" src={Component25} alt=""/>
                <div className="card-i-heading">Crypto Currency</div>
                <div className="card-i-text">Cryptocurrencies also known as digital currencies are fast-growth investment instruments and attracting popularity worldwide. Traders can trade a wide range of crypto-currencies as the ideal asset in CFD trades.</div>
                <div className="card-i-link">Learn More</div>
            </div>
          </Link>

          <Link to="/metals" className="card-item-showtext">
            <div className="card-item">
                <img className="card-i-img" src={Component24} alt=""/>
                <div className="card-i-heading">Metals</div>
                <div className="card-i-text">Spot Metal Trading holds a special place in the heart of every Trader. Metals have attracted traders all over the world since ancient times and continue to do so today.</div>
                <div className="card-i-link">Learn More</div>
            </div>
          </Link>

          <Link to="/commodities" className="card-item-showtext">
            <div className="card-item">
                <img className="card-i-img" src={Component23} alt=""/>
                <div className="card-i-heading">Commodities</div>
                <div className="card-i-text">Investing in commodities can provide investors with diversification, a hedge against inflation, and excess positive returns. Investors may experience volatility when their investments track a single commodity or one sector of the economy.</div>
                <div className="card-i-link">Learn More</div>
            </div>
          </Link>

        </Slider>
      </div>
    </>
  );
}
