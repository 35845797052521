import React from 'react';
import {Link} from "react-router-dom";
// import { Helmet } from "react-helmet-async";
import CommonHelmet from '../../components/CommonHelmet';

// Design Files
// import { useMenu } from '../../components/Header/MenuContext'

// Images Common File
import * as Img from '../../components/Img';
import grap from '../../img/icons/grap.svg';
import cdzgvbsxfaegfsrrfhdtgf from '../../img/cdzgvbsxfaegfsrrfhdtgf.png';
import cdzgvbsxrfhdtgfceafgaeg2 from '../../img/cdzgvbsxrfhdtgfceafgaeg2.png';

import trade5 from '../../img/trade/trade(5).svg'
import trade6 from '../../img/trade/trade(6).svg'
import trade7 from '../../img/trade/trade(7).svg'
import trade8 from '../../img/trade/trade(8).svg'
import CryptoSlider from '../Sliders/CryptoSlider';


export default function PageCrypto() {

  // const { handleScreenChange } = useMenu();

  const questions = [
    {
      question: "DISCOVER HOW TO TRADE?",
      answer: (
        <>
          <p>Download our Education app to learn how to trade on WinproFX Trader's various financial instruments. To get started, we've covered every aspect of trading.</p>

          <ul>
            <li>A Brief Summary of Recent News and Opinions</li>
            <li>Volatility Forecast Based on Technical Analysis</li>
            <li>Brief overview of major currency pairs based on the provided fundamental</li>
          </ul>

          <p>Cryptocurrencies also known as digital currencies are fast-growth investment instruments and attracting popularity worldwide. Traders can trade a wide range of crypto-currencies as the ideal asset in CFD trades. Available on MT5 now you can trade Bitcoin, Ether, Ripple, Lite coin, Dash, and Monaro against the USD dollar and the most innovative and popular digital currencies. Diversify your trading portfolio today with one of the industry’s safest providers of online trading. We offer cryptocurrencies on leverage with the advantage of short selling to deliver an ideal solution for hedging physical holding.</p>
        </>
      ),
    },
    {
      question: "WHAT IS CRYPTOCURRENCY?",
      answer: (
        <>
          <p>Cryptocurrency is a digital payment system that doesn't rely on banks to verify transactions. It’s a peer-to-peer system that can enable anyone anywhere to send and receive payments. Instead of being physical money carried around and exchanged in the real world, cryptocurrency payments exist purely as digital entries to an online database describing specific transactions. When you transfer cryptocurrency funds, the transactions are recorded in a public ledger. Cryptocurrency is stored in digital wallets.</p>
        </>
      ),
    },
    {
      question: "HOW DOES CRYPTOCURRENCY WORK?",
      answer: (
        <>
          <p>Cryptocurrencies run on a distributed public ledger called blockchain, a record of all transactions updated and held by currency holders. Units of cryptocurrency are created through a process called mining, which involves using computer power to solve complicated mathematical problems that generate coins. Users can also buy the currencies from brokers, then store and spend them using cryptographic wallets.</p>
        </>
      ),
    }
  ];

  return (
    <>
      <CommonHelmet 
        title="Best Crypto Currency Trading Platform for Cryptocurrency Trading"
        description="Trade Bitcoin, Ethereum, Ripple & more on our secure platform. Diversify your portfolio with leveraged crypto trades. Start now on the best crypto trading platform"
        keywords="Cryptocurrency Trading Platform , Best Crypto Trading Platform, Bitcoin Trading , Trade Cryptocurrencies, Online Crypto Trading, Secure Cryptocurrency Trading, Crypto Market Trading, Digital Currency Trading, WinProFX Cryptocurrency Trading, Best Forex Trading Broker Platform, Best Forex Market Trading Platform, Best Online Trading Platform For Professional Traders In India, Best Forex Market Trading Platforms For Advanced Traders, Best Forex Trading Platform For India Beginners, Best Trading Brokers For Beginners, Best Crypto Currency Trading Platform, Best Free Funded Forex Account Without Deposit, Forex Broker 0 Spread, Free Funded Account, Free Funded Accounts, Best Forex Trading Platform"
      />

      <div className="d-none" style={{display:"none"}}>
        <h1>Best Crypto Currency Trading Platform for Cryptocurrency Trading</h1>
        <h2>Trade Bitcoin, Ethereum, Ripple & more on our secure platform. Diversify your portfolio with leveraged crypto trades. Start now on the best crypto trading platform</h2>
        <b>Cryptocurrency Trading Platform</b>
        <b>Crypto Market Trading</b>
        <b>Crypto Market Trading</b>
        <img src='' alt='Best Forex Trading Broker Platform, Best Forex Market Trading Platform, Best Online Trading Platform For Professional Traders In India, Best Forex Market Trading Platforms For Advanced Traders, Best Forex Trading Platform For India Beginners, Best Trading Brokers For Beginners, Best Crypto Currency Trading Platform, Best Free Funded Forex Account Without Deposit, Forex Broker 0 Spread, Free Funded Account, Free Funded Accounts, Best Forex Trading Platform' />
      </div>

      <section className="relative maxwidth1920">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx">
            <div className="breadcrumb-bx mt-5 mt-sm-0">
                <Link className="breadcrumb-link" to="/">Home</Link>
                <div className="breadcrumb-link breadcrumb-active">/ Markets / Crypto</div>
            </div>
            <div data-aos="fade-up" className="tlm-heading "><span className="tlm-h-color">CRYPTOCURRENCY</span> <br/> TRADING?</div>
            <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">Cryptocurrencies also known as digital currencies are fast-growing investment instruments attracting popularity worldwide. Traders can trade a wide range of crypto-currencies as the ideal asset in CFD trades. Available on MT5 you can now trade Bitcoin, Ether, Ripple, Litecoin, Dash, and Monero against the USD and other innovative digital currencies. Diversify your trading portfolio today with one of the industry’s safest providers of online trading. We offer cryptocurrencies on leverage with the advantage of short selling, delivering an ideal solution for hedging physical holdings. Explore our Best Crypto Currency Platform for optimal trading experiences.</div>
            <div data-aos="fade-up" data-aos-delay="500" className="tlm-btns">
                <div data-bs-target="#SignUpBtns" data-bs-toggle="modal" className="tlm-btn-items tbi-fill"><span>Open Account</span></div>
                {/* <div onClick={() => handleScreenChange('register-screen')} className="tlm-btn-items tbi-fill"><span>Open Account</span></div> */}
                {/* <div className="tlm-btn-items tbi-none">Try Demo <img src={Img.arrowright} alt="" /> </div> */}
            </div>
          </div>
        </div>
        <div className="textlayout-bg-bx cdgdtheavdzgdrtjrdf affter-none">
          <img data-aos="fade-up" data-aos-delay="900" className="d-none d-sm-block" src={cdzgvbsxfaegfsrrfhdtgf} alt="" />
          <img data-aos="fade-up" data-aos-delay="900" className="d-block d-sm-none " src={cdzgvbsxrfhdtgfceafgaeg2} alt="" />
        </div> 
      </section>

      <div className="py-md-3 py-2"></div>

      <section className="relative overflowhidden">
        <div className="container-lg cl-custome px-0 px-md-3">
          <div className="common-section">

            <div className="d-none d-md-flex row justify-content-center mt-4">

              <div data-aos="fade-right" className="col-lg-5 col-md-6">
                <div className="trades-card-item">
                  <div className="tci-textdata">

                    <div className="tci-td-inner">
                      <img className="tci-data-img" src={trade5} alt="BTUSD" />
                      <div className="tci-data-name">BTUSD<span>Bitcoin</span></div>
                    </div>

                    <div className="tci-td-inner tci-flex-column">
                      <div className="tci-rate"><span>USD</span>1.0932</div>
                      <div className="tci-status tci-red">−0.00072 −0.07%</div>
                    </div>

                  </div>

                  <div className="tci-grap-bx">
                    <img src={grap} alt="" />
                  </div>

                  <div className="tci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

                </div>
              </div>

              <div  data-aos="fade-left" className="col-lg-5 col-md-6">
                <div className="trades-card-item">
                  <div className="tci-textdata">

                    <div className="tci-td-inner">
                      <img className="tci-data-img" src={trade6} alt="ETHUSD" />
                      <div className="tci-data-name">ETHUSD<span>Ethereum</span></div>
                    </div>

                    <div className="tci-td-inner tci-flex-column">
                      <div className="tci-rate"><span>USD</span>1.0932</div>
                      <div className="tci-status tci-green">+0.00072 +0.07%</div>
                    </div>

                  </div>

                  <div className="tci-grap-bx">
                    <img src={grap} alt="" />
                  </div>

                  <div className="tci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

                </div>
              </div>

              <div data-aos="fade-right" className="col-lg-5 col-md-6">
                <div className="trades-card-item">
                  <div className="tci-textdata">

                    <div className="tci-td-inner">
                      <img className="tci-data-img" src={trade8} alt="DOGEUSD" />
                      <div className="tci-data-name">DOGEUSD<span>Dogecoin</span></div>
                    </div>

                    <div className="tci-td-inner tci-flex-column">
                      <div className="tci-rate"><span>USD</span>1.0932</div>
                      <div className="tci-status tci-green">+0.00072 +0.07%</div>
                    </div>

                  </div>

                  <div className="tci-grap-bx">
                    <img src={grap} alt="" />
                  </div>

                  <div className="tci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

                </div>
              </div>

              <div  data-aos="fade-left" className="col-lg-5 col-md-6">
                <div className="trades-card-item">
                  <div className="tci-textdata">

                    <div className="tci-td-inner">
                      <img className="tci-data-img" src={trade7} alt="Polygon" />
                      <div className="tci-data-name">Polygon<span>MAtICUSD</span></div>
                    </div>

                    <div className="tci-td-inner tci-flex-column">
                      <div className="tci-rate"><span>USD</span>1.0932</div>
                      <div className="tci-status tci-red">−0.00072 −0.07%</div>
                    </div>

                  </div>

                  <div className="tci-grap-bx">
                    <img src={grap} alt="" />
                  </div>

                  <div className="tci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

                </div>
              </div>

            </div>

            <div className="d-block d-md-none mt-5">
              <CryptoSlider />
            </div>

          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="common-section">
            <div id="faqsaccordion" className="faqs-accordion mt-4"> {/*  data-bs-parent="#faqsaccordion" */}
              {questions.map((item, index) => (
              <div data-aos="fade-up" className="acc-item" key={index}>
                  <div className="acc-heding collapsed" id={`heading${index}`} data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                    {item.question}
                    <span className="down-icon">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                      </svg> */}
                    </span>
                  </div>

                  <div id={`collapse${index}`} className="collapse" aria-labelledby={`heading${index}`}>
                      <div className="faqs-data">
                        {item.answer}
                      </div>
                  </div>
              </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx py-0">
            <div data-aos="fade-up" className="tlm-heading heading-24">Critical Insights: Unveiling the <span className="tlm-h-color">Pinnacle Learnings</span> for <br/> Strategic Success.</div>
            <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">Beginners and experienced forex traders alike must keep in mind that practice, knowledge, and discipline are key to getting and staying ahead. Using the Best Crypto Trading Platform can also enhance your trading success.</div>
            <div data-aos="fade-up" data-aos-delay="500" className="tlm-btns">
            <a href='https://api.whatsapp.com/send?phone=+971562520783&text=Hello' className="tlm-btn-items tbi-fill"><span><img className='whatappimg' src={Img.whatsapp} alt="" />WhatsApp</span></a>
            </div> 
          </div>
        </div>
      </section>
    </>
  );
};
