import React from 'react';

const LoginSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75" />
        </svg>
    )
}


export default function SignInBtns() {
    return (
        <>
        <div className="modal fade zoom" id="SignInBtns" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content custom-content">

                    <div className="custom-modal-header">
                        <div className="cmh-lable">Login</div>

                        <span className="close-icon" data-bs-dismiss="modal" aria-label="Close">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                            </svg>
                        </span>
                    </div>

                    <div className="custom-modal-data mt-2 mb-4">
                        <div className="row mx-0">
                            <div className="col-12">
                                <a className="sibwcbi common-btn-item cbi-fill" href="https://my.winprofx.com/en/login" target='_blank' rel="noreferrer">
                                    <span>
                                        {LoginSvg()}Go to old interface
                                    </span>
                                </a>
                            </div>
                            <div className="col-12">
                                <a className="sibwcbi common-btn-item cbi-fill" href="https://my.winprofx.com/en/auth/sign-in" target='_blank' rel="noreferrer">
                                    <span>
                                        {LoginSvg()}Go to new interface
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
