import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function CommonHelmet({ title, description, keywords }) {
    return (
        <>
        <Helmet>
            {/* <!-- Site Title --> */}
            <title>{title}</title>

            {/* <!-- Site basic meta tags --> */}
            <meta name="title" content={title} />
            {description && <meta name="description" content={description} /> }
            {keywords && <meta name="keywords" content={keywords} />}

            {/* <!-- Open Graph Meta Tags --> */}
            <meta property="og:title" content={title} />
            {description && <meta property="og:description" content={description} /> }

            {/* <!-- Twitter Meta Tags --> */}
            <meta name="twitter:title" content={title} />
            {description && <meta name="twitter:description" content={description} /> }
        </Helmet>
        </>
    );
}
