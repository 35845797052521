import React from 'react';
import {Link} from "react-router-dom";
import CommonHelmet from '../../components/CommonHelmet';

// Design Files
// import { useMenu } from '../../components/Header/MenuContext'

// Images Common File
import * as Img from '../../components/Img';
import grap from '../../img/icons/grap.svg';

import od1 from '../../img/oil(1).png'
import od2 from '../../img/oil(2).png'
import od3 from '../../img/oil(3).png'
import od4 from '../../img/oil(4).png'
import sfcaezfazegd from '../../img/sfcaezfazegd.png'


import trade13 from '../../img/trade/trade(13).svg'
import trade14 from '../../img/trade/trade(14).svg'
import trade15 from '../../img/trade/trade(15).svg'
import trade16 from '../../img/trade/trade(16).svg'
import OilSlider from '../Sliders/OilSlider';


export default function PageOil() {

  // const { handleScreenChange } = useMenu();

  const questions = [
    {
      question: "How to start oil trading?",
      answer: (
        <>
          <p>Before you start trading oil, it is essential to do your research and understand the market. You need to know what factors affect oil prices and how those factors may change in the future. Also, understand the different types of oil you can trade on the market and the products that use each.</p>
        </>
      ),
    },
    {
      question: "What is the best strategy for crude oil trading?",
      answer: (
        <>
           <p>Before you start trading oil, it is essential to do your research and understand the market. You need to know what factors affect oil prices and how those factors may change in the future. Also, understand the different types of oil you can trade on the market and the products that use each.</p>
        </>
      ),
    },
    {
      question: "What is the best indicator for crude oil?",
      answer: (
        <>
          <p>Before you start trading oil, it is essential to do your research and understand the market. You need to know what factors affect oil prices and how those factors may change in the future. Also, understand the different types of oil you can trade on the market and the products that use each.</p>
        </>
      ),
    }
  ];

  return (
    <>
      <CommonHelmet 
        title="The Best Platform Oil and Crude for Trading and Experience"
        description="Trade energy futures with confidence on our Best Oil Trading Platform. Hedge against price fluctuations and navigate the market effectively. Start trading today."
        keywords="Oil Trading Platform, Best Oil Trading Platform, Crude Oil Trading, Oil Futures Trading, Online Oil Trading, Trade Crude Oil, Commodities Trading, Oil Market Trading, Trading Platform, WinProFX Oil Trading, Best Forex Trading Broker Platform, Best Forex Market Trading Platform, Best Online Trading Platform For Professional Traders In India, Best Forex Market Trading Platforms For Advanced Traders, Best Forex Trading Platform For India Beginners, Best Trading Brokers For Beginners, Best Crypto Currency Trading Platform, Best Free Funded Forex Account Without Deposit, Forex Broker 0 Spread, Free Funded Account, Free Funded Accounts, Best Forex Trading Platform"
      />
      <div className="d-none" style={{display:"none"}}>
        <h1>The Best Platform Oil and Crude for Trading and Experience</h1>
        <h2>Trade energy futures with confidence on our Best Oil Trading Platform. Hedge against price fluctuations and navigate the market effectively. Start trading today.</h2>
        <b>Oil Trading Platform</b>
        <b>Best Oil Trading Platform</b>
        <b>Crude Oil Trading</b>
        <b>Oil Futures Trading</b>
        <b>Online Oil Trading</b>
        <b>Trade Crude Oil</b>
        <b>Commodities Trading</b>
        <b>Oil Market Trading</b>
        <b>Trading Platform</b>
        <b>WinProFX Oil Trading</b>
        <img src="" alt="Best Forex Trading Broker Platform, Best Forex Market Trading Platform, Best Online Trading Platform For Professional Traders In India, Best Forex Market Trading Platforms For Advanced Traders, Best Forex Trading Platform For India Beginners, Best Trading Brokers For Beginners, Best Crypto Currency Trading Platform, Best Free Funded Forex Account Without Deposit, Forex Broker 0 Spread, Free Funded Account, Free Funded Accounts, Best Forex Trading Platform"/>
      </div>

      <section className="relative maxwidth1920">
        <div className="container-lg cl-custome py-5">
          <div className="textlayout-main-bx">
            <div className="breadcrumb-bx mt-4 mt-sm-0">
                <Link className="breadcrumb-link" to="/">Home</Link>
                <div className="breadcrumb-link breadcrumb-active">/ Markets / Oil</div>
            </div>
            <div data-aos="fade-up" className="tlm-heading">Mastering <span className="tlm-h-color">Energy Futures</span>: Navigating <br/> Contracts for Strategic Market Prowess</div>
            <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">Energy futures are contracts to buy or sell fossil fuels or related products at a set date and price. Investors use these contracts to hedge against price fluctuations. Explore our Best Oil Trading Platform to study contracts and navigate the energy market effectively.</div>
            <div data-aos="fade-up" data-aos-delay="500" className="tlm-btns">
                {/* <div data-bs-target="#SignUpBtns" data-bs-toggle="modal" className="tlm-btn-items tbi-fill"><span>Open Account</span></div> */}
                <a href="https://my.winprofx.com/en/register" target='_blank' rel="noreferrer" className="tlm-btn-items tbi-fill"><span>Open Account</span></a>
                {/* <div onClick={() => handleScreenChange('register-screen')} className="tlm-btn-items tbi-fill"><span>Open Account</span></div> */}
                {/* <div className="tlm-btn-items tbi-none">Try Demo <img src={Img.arrowright} alt="" /> </div> */}
            </div>
          </div>
        </div>

        <div data-aos="fade-up" data-aos-delay="900" className="textlayout-bg-bx d-block d-sm-none">
          <img src={sfcaezfazegd} alt='' />
        </div> 


        <div  data-aos="fade-up" data-aos-delay="900" className="textlayout-drop-img d-none d-sm-block">
            <img src={od4} alt='' />
            <img src={od2} alt='' />
            <img src={od3} alt='' />
            <img src={od1} alt='' />

        </div>

      </section>

      <div className="py-md-3 py-2"></div>

      <section className="relative overflowhidden">
        <div className="container-lg cl-custome px-0 px-md-3">
          <div className="common-section">
            <div className="d-none d-md-flex row justify-content-center mt-4">

              <div data-aos="fade-right" className="col-lg-5 col-md-6">
                <div className="trades-card-item">
                  <div className="tci-textdata">

                    <div className="tci-td-inner">
                      <img className="tci-data-img" src={trade16} alt="" />
                      <div className="tci-data-name">Crude Oil Futures<span>Crude Oil</span></div>
                    </div>

                    <div className="tci-td-inner tci-flex-column">
                      <div className="tci-rate"><span>USD</span>1.0932</div>
                      <div className="tci-status tci-red">−0.00072 −0.07%</div>
                    </div>

                  </div>

                  <div className="tci-grap-bx">
                    <img src={grap} alt="" />
                  </div>

                  <div className="tci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

                </div>
              </div>

              <div data-aos="fade-left" className="col-lg-5 col-md-6">
                <div className="trades-card-item">
                  <div className="tci-textdata">

                    <div className="tci-td-inner">
                      <img className="tci-data-img" src={trade15} alt="" />
                      <div className="tci-data-name">ETHANOL FUTURES<span>ETHANOL</span></div>
                    </div>

                    <div className="tci-td-inner tci-flex-column">
                      <div className="tci-rate"><span>USD</span>1.0932</div>
                      <div className="tci-status tci-green">+0.00072 +0.07%</div>
                    </div>

                  </div>

                  <div className="tci-grap-bx">
                    <img src={grap} alt="" />
                  </div>

                  <div className="tci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

                </div>
              </div>

              <div data-aos="fade-right" className="col-lg-5 col-md-6">
                <div className="trades-card-item">
                  <div className="tci-textdata">

                    <div className="tci-td-inner">
                      <img className="tci-data-img" src={trade14} alt="" />
                      <div className="tci-data-name">NY Harbor ULSD Futures<span>NY Harbor ULSD</span></div>
                    </div>

                    <div className="tci-td-inner tci-flex-column">
                      <div className="tci-rate"><span>USD</span>1.0932</div>
                      <div className="tci-status tci-green">+0.00072 +0.07%</div>
                    </div>

                  </div>

                  <div className="tci-grap-bx">
                    <img src={grap} alt="" />
                  </div>

                  <div className="tci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

                </div>
              </div>
              
              <div data-aos="fade-left" className="col-lg-5 col-md-6">
                <div className="trades-card-item">
                  <div className="tci-textdata">

                    <div className="tci-td-inner">
                      <img className="tci-data-img" src={trade13} alt="" />
                      <div className="tci-data-name">RBOB Gasoline Futures<span>RBOB Gasoline</span></div>
                    </div>

                    <div className="tci-td-inner tci-flex-column">
                      <div className="tci-rate"><span>USD</span>1.0932</div>
                      <div className="tci-status tci-red">−0.00072 −0.07%</div>
                    </div>

                  </div>

                  <div className="tci-grap-bx">
                    <img src={grap} alt="" />
                  </div>

                  <div className="tci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

                </div>
              </div>

            </div>
            <div className="d-block d-md-none mt-5">
              <OilSlider />
            </div>
          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="common-section">
            <div id="faqsaccordion" className="faqs-accordion mt-4"> {/*  data-bs-parent="#faqsaccordion" */}
              {questions.map((item, index) => (
              <div data-aos="fade-up" className="acc-item" key={index}>
                  <div className="acc-heding collapsed" id={`heading${index}`} data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                    {item.question}
                    <span className="down-icon">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                      </svg> */}
                    </span>
                  </div>

                  <div id={`collapse${index}`} className="collapse" aria-labelledby={`heading${index}`}>
                      <div className="faqs-data">
                        {item.answer}
                      </div>
                  </div>
              </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx py-0">
            <div data-aos="fade-up" className="tlm-heading heading-24">Critical Insights: Unveiling the <span className="tlm-h-color">Pinnacle Learnings</span> for <br/> Strategic Success.</div>
            <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">Beginners and experienced forex traders alike must keep in mind that practice, knowledge, and discipline are key to getting and staying ahead. Utilizing the Best Crude oil Prices Platform enhances trading proficiency.</div>
            <div data-aos="fade-up" data-aos-delay="500" className="tlm-btns">
            <a href='https://api.whatsapp.com/send?phone=+971562520783&text=Hello' className="tlm-btn-items tbi-fill"><span><img className='whatappimg' src={Img.whatsapp} alt="" />WhatsApp</span></a>
            </div> 
          </div>
        </div>
      </section>
    </>
  );
};
