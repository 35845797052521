import React from 'react';

// Slider Code
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

// Images
import client1 from '../../img/client/Untitled-1.jpg';
import client2 from '../../img/client/Untitled-2.jpg';
import client3 from '../../img/client/Untitled-3.jpg';
import client4 from '../../img/client/Untitled-4.jpg';
// import client5 from '../../img/client/Untitled-5.jpg';
import client6 from '../../img/client/Untitled-6.jpg';
import client7 from '../../img/client/Untitled-7.jpg';
import client8 from '../../img/client/Untitled-8.jpg';

export default function Clientsslider() {

    const clientsslider = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 1500,
        variableWidth: true,
        autoplaySpeed: 1500,
        // cssEase: 'linear',
        centerMode: true,
        pauseOnHover: true,
        // pauseOnFocus: true,
    };
    
    return (
      <>
        <div data-aos="fade-up">
            <Slider className={`maxwidth1920 clientsslider-bx slick-hover`} {...clientsslider}>
                
                <a href="https://www.streetinsider.com/Newsfile/WinproFX+Announces+Milestone+in+Commitment+to+Online+Trading+Excellence/22489071.html" target="_blank"  rel="noreferrer" className="cs-item">
                    <img src={client1} alt="" />
                </a>
                
                <a href="https://finance.yahoo.com/news/winprofx-announces-milestone-commitment-online-063100151.html" target="_blank"  rel="noreferrer" className="cs-item">
                    <img src={client2} alt="" />
                </a>

                <a href="https://www.thewhig.com/newsfile/189715-winprofx-announces-milestone-in-commitment-to-online-trading-excellence" target="_blank"  rel="noreferrer" className="cs-item">
                    <img src={client3} alt="" />
                </a>

                <a href="https://www.theglobeandmail.com/investing/markets/markets-news/Newsfile/22488517/winprofx-announces-milestone-in-commitment-to-online-trading-excellence/" target="_blank"  rel="noreferrer" className="cs-item">
                    <img src={client4} alt="" />
                </a>

                {/* <a href="https://www.benzinga.com/pressreleases/24/01/36472404/winprofx-the-epitome-of-excellence-in-online-trading" target="_blank"  rel="noreferrer" className="cs-item">
                    <img src={client5} alt="" />
                </a> */}
                
                <a href="https://www.digitaljournal.com/pr/news/newsfile/winprofx-announces-milestone-in-commitment-to-online-trading-excellence" target="_blank"  rel="noreferrer" className="cs-item">
                    <img src={client6} alt="" />
                </a>

                <a href="https://huffmag.com/winprofx-the-epitome-of-excellence-in-online-trading/" target="_blank"  rel="noreferrer" className="cs-item">
                    <img src={client7} alt="" />
                </a>

                <a href="https://ceo.ca/@newsfile/winprofx-announces-milestone-in-commitment-to-online" target="_blank"  rel="noreferrer" className="cs-item">
                    <img src={client8} alt="" />
                </a>

            </Slider>
        </div>
      </>
    );
}
  