import React from 'react';
import {Link} from "react-router-dom";
import CommonHelmet from '../../components/CommonHelmet';

// Design Files
// import { useMenu } from '../../components/Header/MenuContext'

// Images Common File
import * as Img from '../../components/Img';
import grap from '../../img/icons/grap.svg';
import fsegs from '../../img/fsegs.png'

import trade8 from '../../img/trade/trade(8).svg'
import trade9 from '../../img/trade/trade(9).svg'
import trade10 from '../../img/trade/trade(10).svg'
import trade11 from '../../img/trade/trade(11).svg'
import Metalsslider from '../Sliders/Metalsslider';


export default function PageMetals() {

  // const { handleScreenChange } = useMenu();

  const questions = [
    {
      question: "WHY TRADE METAL WITH WINPROFX",
      answer: (
        <>
          <p>WinproFX a rich track record spanning more than 16 years. We have delivered our traders great opportunities and unparalleled trading experience. This positions us as one of the leading brokers when it comes to metals trading. So, if you’re looking to trade in Gold, there are many reasons to choose WinproFX:</p>

          <ul>
            <li>Thousands of happy and satisfied traders</li>
            <li>Round-the-clock support available</li>
            <li>Super-fast withdrawal and executionl</li>
            <li>Rich analytics to make informed decisions</li>
          </ul>

          <p>For example, if you choose to trade GBP/USD (British pound/US dollar) and you think the value of the GBP will rise against USD, you go long (buy). If you think GBP will fall against USD, you go short (sell). If your prediction is correct, you make a profit. If your prediction is incorrect, you would make a loss. Remember, losses can exceed deposits..</p>
        </>
      ),
    },
    {
      question: "STEP UP YOUR COMMODITIES TRADING GAME",
      answer: (
        <>
          <p>Gold is the most actively traded metal. Based on the law of supply and demand, its price is determined by the 24/7 market. Other factors like the value of the US dollar, industrial demand, as well as inflation also influence its price. If you’re looking to trade metals, get started on the right track with WinproFX and stride confidently to become a seasoned trader.</p>
        </>
      ),
    },
    {
      question: "HOW DOES METAL TRADING WORK?",
      answer: (
        <>
          <p>In order to optimize returns, the traders must have a clear understanding of all the factors that affect the price of gold and other metals. Interestingly, the price drivers of precious metals are quite different in comparison to other instruments.</p>
        </>
      ),
    }
  ];

  return (
    <>
      <CommonHelmet 
        title="Premier Platform for Trading Precious Metals like Gold and Silver"
        description="Discover the all over of spot metal trading with WinProFX. Benefit from the lowest spreads on precious metals like gold and silver. Explore our top platform for a premier trading experience. Start optimizing your trades today"
        keywords="Metals Trading Platform, Precious Metals Trading, Gold and Silver Trading, Best Metals Trading , Trade Metals Online, Metal Commodity Trading, WinProFX Metals Platform, Trading Gold and Silver, Metals Investment Platform, Precious Metals Market Solutions, Best Forex Trading Broker Platform, Best Forex Market Trading Platform, Best Online Trading Platform For Professional Traders In India, Best Forex Market Trading Platforms For Advanced Traders, Best Forex Trading Platform For India Beginners, Best Trading Brokers For Beginners, Best Crypto Currency Trading Platform, Best Free Funded Forex Account Without Deposit, Forex Broker 0 Spread, Free Funded Account, Free Funded Accounts, Best Forex Trading Platform"
      />

      <div className="d-none" style={{display:"none"}}>
        <h1>Premier Platform for Trading Precious Metals like Gold and Silver</h1>
        <h2>Discover the all over of spot metal trading with WinProFX. Benefit from the lowest spreads on precious metals like gold and silver. Explore our top platform for a premier trading experience. Start optimizing your trades today</h2>
        <b>Metals Trading Platform</b>
        <b>Precious Metals Trading</b>
        <b>Gold and Silver Trading</b>
        <b>Best Metals Trading</b>
        <img src="" alt="Best Forex Trading Broker Platform, Best Forex Market Trading Platform, Best Online Trading Platform For Professional Traders In India, Best Forex Market Trading Platforms For Advanced Traders, Best Forex Trading Platform For India Beginners, Best Trading Brokers For Beginners, Best Crypto Currency Trading Platform, Best Free Funded Forex Account Without Deposit, Forex Broker 0 Spread, Free Funded Account, Free Funded Accounts, Best Forex Trading Platform" />
      </div>

      <section className="relative maxwidth1920">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx">
            <div className="breadcrumb-bx mt-5 mt-sm-0">
                <Link className="breadcrumb-link" to="/">Home</Link>
                <div className="breadcrumb-link breadcrumb-active">/ Markets / Metals</div>
            </div>
            <div data-aos="fade-up" className="tlm-heading">TRADING <br/> PRECIOUS <span className="tlm-h-color">METALS</span></div>
            <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">Spot Metal Trading holds a special place in the heart of every trader. Metals have attracted traders all over the world since ancient times and continue to do so today. WinproFX Trader offers the lowest ever spreads in various metals based on your core interest and preference. Explore our Best Precious Metals Trading Platform for optimal trading experiences.</div>
            <div data-aos="fade-up" data-aos-delay="500" className="tlm-btns">
                {/* <div data-bs-target="#SignUpBtns" data-bs-toggle="modal" className="tlm-btn-items tbi-fill"><span>Open Account</span></div> */}
                <a href="https://my.winprofx.com/en/register" target='_blank' rel="noreferrer" className="tlm-btn-items tbi-fill"><span>Open Account</span></a>
                {/* <div onClick={() => handleScreenChange('register-screen')} className="tlm-btn-items tbi-fill"><span>Open Account</span></div> */}
                {/* <div className="tlm-btn-items tbi-none">Try Demo <img src={Img.arrowright} alt="" /> </div> */}
            </div>
          </div>
        </div>

          <div className="textlayout-bg-bx esgdsgsrxh">
            <img data-aos="fade-up" data-aos-delay="900" className="afegsrghsdr"  src={fsegs} alt="" />
          </div> 
   
      </section>

      <div className="py-md-3 py-2"></div>

      <section className="relative overflowhidden">
        <div className="container-lg cl-custome px-0 px-md-3">
          <div className="common-section">
            <div className="d-none d-md-flex row justify-content-center mt-4">

              <div data-aos="fade-right" className="col-lg-5 col-md-6">
                <div className="trades-card-item">
                  <div className="tci-textdata">

                    <div className="tci-td-inner">
                      <img className="tci-data-img" src={trade11} alt="Gold" />
                      <div className="tci-data-name">Gold<span>Gold Futures</span></div>
                    </div>

                    <div className="tci-td-inner tci-flex-column">
                      <div className="tci-rate"><span>USD</span>1.0932</div>
                      <div className="tci-status tci-red">−0.00072 −0.07%</div>
                    </div>

                  </div>

                  <div className="tci-grap-bx">
                    <img src={grap} alt="" />
                  </div>

                  <div className="tci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

                </div>
              </div>

              <div  data-aos="fade-left" className="col-lg-5 col-md-6">
                <div className="trades-card-item">
                  <div className="tci-textdata">

                    <div className="tci-td-inner">
                      <img className="tci-data-img" src={trade10} alt="Copper" />
                      <div className="tci-data-name">Copper<span>Copper Futures</span></div>
                    </div>

                    <div className="tci-td-inner tci-flex-column">
                      <div className="tci-rate"><span>USD</span>1.0932</div>
                      <div className="tci-status tci-green">+0.00072 +0.07%</div>
                    </div>

                  </div>

                  <div className="tci-grap-bx">
                    <img src={grap} alt="" />
                  </div>

                  <div className="tci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

                </div>
              </div>

              <div data-aos="fade-right" className="col-lg-5 col-md-6">
                <div className="trades-card-item">
                  <div className="tci-textdata">

                    <div className="tci-td-inner">
                      <img className="tci-data-img" src={trade9} alt="Palladium" />
                      <div className="tci-data-name">Palladium<span>Palladium Futures</span></div>
                    </div>

                    <div className="tci-td-inner tci-flex-column">
                      <div className="tci-rate"><span>USD</span>1.0932</div>
                      <div className="tci-status tci-green">+0.00072 +0.07%</div>
                    </div>

                  </div>

                  <div className="tci-grap-bx">
                    <img src={grap} alt="" />
                  </div>

                  <div className="tci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

                </div>
              </div>

              <div  data-aos="fade-left" className="col-lg-5 col-md-6">
                <div className="trades-card-item">
                  <div className="tci-textdata">

                    <div className="tci-td-inner">
                      <img className="tci-data-img" src={trade8} alt="LEAD" />
                      <div className="tci-data-name">LEAD<span>LEAD FUTURES</span></div>
                    </div>

                    <div className="tci-td-inner tci-flex-column">
                      <div className="tci-rate"><span>USD</span>1.0932</div>
                      <div className="tci-status tci-red">−0.00072 −0.07%</div>
                    </div>

                  </div>

                  <div className="tci-grap-bx">
                    <img src={grap} alt="" />
                  </div>

                  <div className="tci-provider">
                    <img src={Img.dataprovider} alt="" />
                    Data Provider
                  </div>

                </div>
              </div>

            </div>
            <div className="d-block d-md-none mt-5">
              <Metalsslider />
            </div>
          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="common-section">
            <div id="faqsaccordion" className="faqs-accordion mt-4"> {/*  data-bs-parent="#faqsaccordion" */}
              {questions.map((item, index) => (
              <div data-aos="fade-up" className="acc-item" key={index}>
                  <div className="acc-heding collapsed" id={`heading${index}`} data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                    {item.question}
                    <span className="down-icon">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                      </svg> */}
                    </span>
                  </div>

                  <div id={`collapse${index}`} className="collapse" aria-labelledby={`heading${index}`}>
                      <div className="faqs-data">
                        {item.answer}
                      </div>
                  </div>
              </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx py-0">
            <div data-aos="fade-up" className="tlm-heading heading-24">Critical Insights: Unveiling the <span className="tlm-h-color">Pinnacle Learnings</span> for <br/> Strategic Success.</div>
            <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">Beginners and experienced forex traders alike must keep in mind that practice, knowledge, and discipline are key to getting and staying ahead. Utilizing the Best Gold and Silver Trading Platform can enhance trading strategies effectively.</div>
            <div data-aos="fade-up" data-aos-delay="500" className="tlm-btns">
            <a href='https://api.whatsapp.com/send?phone=+971562520783&text=Hello' className="tlm-btn-items tbi-fill"><span><img className='whatappimg' src={Img.whatsapp} alt="" />WhatsApp</span></a>
            </div> 
          </div>
        </div>
      </section>
    </>
  );
};
