import React from 'react';
import {Link} from "react-router-dom";
import CommonHelmet from '../../components/CommonHelmet';


// import { useMenu } from '../../components/Header/MenuContext';

// Images
import team1 from '../../img/ourteam/team(1).png';
import team2 from '../../img/ourteam/team(2).png';
import team3 from '../../img/ourteam/team(3).png';
import team4 from '../../img/ourteam/team(4).png';
import Clientsslider from '../Sliders/Clientsslider';

export default function PageAbout() {
    // const { handleScreenChange } = useMenu();

    const questions = [
        {
          question: "What is WinproFX?",
          answer: (
            <>
                <p>WinproFX maintains high standards in its services, recognizing the pivotal role of quality for both the company and its clients. The belief in the necessity of versatile thinking and a unified set of business principles underpins our commitment to providing diverse forex services.</p>

                <p>We aim to adapt to evolving market conditions and consistently meet the diverse needs of our clientele.</p>

                <p>WinproFX stands out for several reasons:</p>

                <p><strong>Global Regulation</strong>: WinproFX is reputed globally, ensuring compliance with industry standards and providing a secure trading environment.</p>

                <p><strong>Nanosecond Execution</strong>: The platform boasts nanosecond execution, allowing for quick and efficient trade processing, which is crucial in fast-paced financial markets.</p>

                <p><strong>Lightning-Fast Withdrawals</strong>: WinproFX offers swift withdrawal processes, ensuring that you have timely access to your funds when needed.</p>

                <p><strong>Wide Range of Tradable Instruments</strong>: With over 300 tradable instruments, WinproFX provides a diverse portfolio, allowing traders to explore various markets and investment opportunities.</p>

                <p><strong>Tightest Spreads</strong>: WinproFX offers tight spreads, minimizing the difference between buying and selling prices, which can enhance profitability for traders.</p>
            </>
          ),
        },

        {
          question: "How do I start trading with WinproFX?",
          answer: (
            <>
                <p className='mt-1 mb-1' style={{fontSize:"16px"}}><strong>Create an Account</strong>:</p>
                <p>Visit the official WinproFX website. 
                    {/* <a data-bs-target="#SignUpBtns" data-bs-toggle="modal">Register Link</a> */}
                    <a rel="noreferrer" href="https://my.winprofx.com/en/register" target='_blank'>Register Link</a>
                </p>
                <p>Look for a "Register" or "Open an Account" option.</p>
                <p>Provide the required information, including personal details and contact information.</p>
                <p>Agree to the terms and conditions.</p>

                <p className='mt-4 mb-1' style={{fontSize:"16px"}}><strong>Verification (KYC)</strong>:</p>
                <p>Complete  the required identity verification process by submitting identification documents to comply with regulatory requirements.</p>

                <p className='mt-4 mb-1' style={{fontSize:"16px"}}><strong>Deposit Funds</strong>:</p>
                <p>Log in to your newly created account.</p>
                <p>Go to the deposit or funding section.</p>
                <p>Choose your preferred payment method and deposit funds into your trading account.</p>

                <p className='mt-4 mb-1' style={{fontSize:"16px"}}><strong>Select a Trading Platform</strong>:</p>
                <p>Choose from the available trading platform - MetaTrader 5 (MT5). </p>
                <p>Download and Install the Trading Platform.</p>

                <p className='mt-4 mb-1' style={{fontSize:"16px"}}><strong>Login to the Trading Platform</strong>:</p>
                <p>Open the trading platform.</p>
                <p>Enter your account credentials to log in.</p>
                <p>If you encounter any difficulties, WinproFX's customer support should be able to assist you. <Link to="/contact">Contact us</Link></p>
            </>
          ),
        },

        {
          question: "Which Financial instruments can I trade with WinproFX?",
          answer: (
            <>
              <p>Common categories of financial instruments that traders can potentially access on WinproFX:</p>
              <p><strong>Forex (Foreign Exchange)</strong>: Currency pairs, such as EUR/USD, GBP/JPY, USD/JPY, etc.</p>
              <p><strong>Indices</strong>: Benchmarks representing the performance of a group of stocks on a specific exchange such as S&P500, US30, etc.</p>
              <p><strong>Commodities</strong>: Such as oil, agricultural products, etc.</p>
              <p><strong>Precious Metals</strong>: Assets like gold, silver, platinum, palladium, etc.</p>
              <p><strong>Cryptocurrencies</strong>: Digital currencies like Bitcoin, Ethereum, Litecoin, etc.</p>
            </>
          ),
        },

        {
          question: "How can I deposit and withdraw funds on WinproFX?",
          answer: (
            <>
              <p>For clients convenience WinproFX provides deposit and withdrawal options via our Banking and Crypto network partners. Feel free to connect with our Client Servicing team for further assistance. </p>
            </>
          ),
        },

        {
          question: "What trading platforms does WinproFX support?",
          answer: (
            <>
              <p>WinproFX is available on prominent trading platform -  MetaTrader 5 (MT5).</p> 

              <p>MT5 is accessible for trading on various devices, including:</p>

              <p><strong>Computer</strong>: Via any web browser or a desktop application.</p>
              <p><strong>Android device</strong>: Through a web browser, the MT5 mobile app.</p>
              <p><strong>iOS device</strong>: Via a mobile web browser.</p>

              <p>Furthermore, you have the flexibility to open both demo and real accounts on all this platform, allowing you to explore and experience the features before engaging in live trading.</p>
            </>
          ),
        },

        {
          question: "Does WinproFX provide educational resources for traders?",
          answer: (
            <>
                <p>WinproFX, provides educational materials to help traders (Beginners. intermediate or expert) improve their skills and knowledge in the forex market.</p>

                <p>Typically, the forex educational resources may include:</p>

                <p><strong>Training Videos</strong>: Video tutorials covering various aspects of forex trading, from basics to advanced strategies.</p>

                <p><strong>Educational Articles and Guides</strong>: Written content explaining key concepts, trading strategies, risk management, and market analysis.</p>

                <p><strong>Demo Accounts</strong>: Virtual trading accounts that allow users to practice trading in a risk-free environment.</p>

                <p><strong>Market Analysis</strong>: Regular market analysis reports to help traders understand market trends.</p>

                <p>You can also click on the education  section and additionally, you can contact our customer support for more detailed information. <Link to="/education">(Education link)</Link>.</p>
            </>
          ),
        },

        // {
        //   question: "Is there a demo account available on WinproFX?",
        //   answer: (
        //     <>
        //       <p>WinproFX is a leading online trading platform, offering a diverse range of financial instruments. Our platform is designed to provide traders with a secure and user-friendly environment for navigating global markets.</p>
        //     </>
        //   ),
        // },

        {
          question: "How can I contact WinproFX customer support?",
          answer: (
            <>
              <p>WhatsApp / Email / live chat link.</p>
            </>
          ),
        },
    ];

  return (
    <>
        <CommonHelmet 
            title="Best Forex Market Trading Platforms For Advanced Traders"
            description="The Top platform for Forex, CFD, and commodities trading. Explore our services and start enhancing your trading efficiency today"
            keywords="Forex Trading Experts, Forex Trading Team,  Forex Platform, Forex Trading Company, WinproFx Services Overview, Best Forex Trading Broker Platform, Best Forex Market Trading Platform, Best Online Trading Platform For Professional Traders In India, Best Forex Market Trading Platforms For Advanced Traders, Best Forex Trading Platform For India Beginners, Best Trading Brokers For Beginners, Best Crypto Currency Trading Platform, Best Free Funded Forex Account Without Deposit, Forex Broker 0 Spread, Free Funded Account, Free Funded Accounts Best Forex Trading Platform"
        />
        <div className="d-none" style={{display:"none"}}>
            <h1>Best Forex Market Trading Platforms For Advanced Traders</h1>
            <h2>The top platform for Forex, CFD, and commodities trading. Explore our services and start enhancing your trading efficiency today</h2>
            <b>Best Popular Forex Trading Platform</b>
            <b>FOREX</b>
            <b>CFD</b>
            <b>commodities markets</b>
            <img src="" alt="Forex Trading Experts, Forex Trading Team,  Forex Platform, Forex Trading Company, WinproFx Services Overview, Best Forex Trading Broker Platform, Best Forex Market Trading Platform, Best Online Trading Platform For Professional Traders In India, Best Forex Market Trading Platforms For Advanced Traders, Best Forex Trading Platform For India Beginners, Best Trading Brokers For Beginners, Best Crypto Currency Trading Platform, Best Free Funded Forex Account Without Deposit, Forex Broker 0 Spread, Free Funded Account, Free Funded Accounts Best Forex Trading Platform" />
        </div>

        <section className="relative pb-4">
            <div className="container-lg cl-custome">
                <div className="textlayout-main-bx">
                    <div className="breadcrumb-bx">
                        <Link className="breadcrumb-link" to="/">Home</Link>
                        <div className="breadcrumb-link breadcrumb-active">/ About Us</div>
                    </div>
                    <div data-aos="fade-up" className="tlm-heading">Pioneering Excellence: Redefining Trading <br/> Standards at WinproFX</div>
                    <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">Empowering Traders Worldwide</div>
                    <div data-aos="fade-up" data-aos-delay="500" className="tlm-sub-text">Welcome to WinproFX, the world's leading Best Popular Platform Forex Trading designed for traders of all levels. For those interested in FOREX, CFD, or commodities markets, we strive to provide programs, services, and information that will make them more profitable, self-sufficient, and efficient.</div>
                </div>
            </div>
        </section>

        <section className="pt-5 pb-5">
            <div className="container-lg cl-custome">
                <div className="common-section">

                    <div className="common-heading-bx">
                        <div className="common-heading">Discovering Distinction: What Sets Our Journey Apart?</div>
                        <div className="common-sub-heading">Envisioned as a pioneering force, WinproFX aspires to be a catalyst for positive change in the world of finance. We aim to provide a dynamic and secure trading environment that fosters success, creating opportunities for both seasoned professionals and budding enthusiasts.</div>
                    </div>

                    <div className="row justify-content-center mt-5">
                        <div data-aos="fade-up" className="col-lg-5 col-sm-6 col-6 my-3">
                            <div className="card-item funded-card-item">
                                <div className="card-i-heading color-electricblue">Innovative Aspirations</div>
                                <div className="card-i-text max-width-189">Breaking free from conventions, our journey is marked by a relentless pursuit of innovation, weaving a narrative that transcends the ordinary.</div>
                            </div>
                        </div>
                        <div data-aos="fade-up" className="col-lg-5 col-sm-6 col-6 my-3">
                            <div className="card-item funded-card-item">

                                <div className="card-i-heading color-electricblue">Client Empowerment</div>
                                <div className="card-i-text max-width-189">It's more than a business; it's a mission to empower. Our commitment lies in elevating each client, forging a path where success is not just a possibility but an inevitability.</div>
                            </div>
                        </div>
                        <div data-aos="fade-up" className="col-lg-5 col-sm-6 col-6 my-3">
                            <div className="card-item funded-card-item">
                                <div className="card-i-heading color-electricblue">Trailblazing Integrity</div>
                                <div className="card-i-text max-width-189">Beyond transactions, we uphold a standard of unwavering integrity. Every interaction is a testament to our commitment to transparency, trust, and ethical conduct.</div>
                            </div>
                        </div>
                        <div data-aos="fade-up" className="col-lg-5 col-sm-6 col-6 my-3">
                            <div className="card-item funded-card-item">
                                <div className="card-i-heading color-electricblue">Community of Achievers</div>
                                <div className="card-i-text max-width-189">We're not just a platform; we're a thriving community of achievers. Join a collective journey where aspirations meet accomplishments, and individual successes contribute to a shared legacy.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div className="py-2"></div>

        <section className="pt-5 pb-5 bg-darkblue-100 d-none">
            <div className="container-lg cl-custome">
                <div className="common-section">

                    <div className="common-heading-bx">
                        <div className="common-heading">Meet the Powerhouse: Our Winning Team</div>
                    </div>

                    <div className="row">
                        <div className="col-md-4 mt-2 mt-md-4">
                            <div className="common-heading-bx">
                                <div className="common-sub-heading">At the heart of WinproFX, we boast a dynamic team of industry experts, each contributing a unique blend of skills and passion to the collective success of our platform. Committed to excellence, our diverse team collaborates seamlessly to navigate the ever-evolving landscape of finance, ensuring our clients receive top-tier service and cutting-edge solutions.</div>
                            </div>
                        </div>
                        <div className="col-md-8  md-0 mt-md-4">
                            <div className="our-team-bx">

                                <div className="our-team-card-item">
                                    <div className="otci-img">
                                        <img src={team1} alt="" />
                                    </div>
                                    <div className="otci-text">
                                        <div className="otci-t-name">Alex Henderson</div>
                                        <div className="otci-t-position">CEO</div>
                                        <div className="otci-t-about">A visionary leader with a track record of steering financial enterprises towards success, Alex Henderson drives the strategic direction of WinproFX.</div>
                                    </div>
                                </div>
                                
                                <div className="our-team-card-item">
                                    <div className="otci-img">
                                        <img src={team4} alt="" />
                                    </div>
                                    <div className="otci-text">
                                        <div className="otci-t-name">Olivia Turner</div>
                                        <div className="otci-t-position">Head of Operations</div>
                                        <div className="otci-t-about">With a meticulous focus on efficiency, Olivia Turner ensures the seamless day-to-day operations that form the backbone of our platform.</div>
                                    </div>
                                </div>

                                <div className="our-team-card-item">
                                    <div className="otci-img">
                                        <img src={team3} alt="" />
                                    </div>
                                    <div className="otci-text">
                                        <div className="otci-t-name">Ethan Ramirez</div>
                                        <div className="otci-t-position">Chief Technology Officer</div>
                                        <div className="otci-t-about">At the helm of technological advancements, Ethan Ramirez leads our tech team in creating and maintaining a secure, cutting-edge trading environment.</div>
                                    </div>
                                </div>

                                <div className="our-team-card-item">
                                    <div className="otci-img">
                                        <img src={team2} alt="" />
                                    </div>
                                    <div className="otci-text">
                                        <div className="otci-t-name">Sophia Chang</div>
                                        <div className="otci-t-position">Customer Success Manager</div>
                                        <div className="otci-t-about">A dedicated advocate for our clients, Sophia Chang ensures that every user receives the support needed to thrive in the markets.</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <div className="py-2"></div>

        <section className="pt-3 pb-3">
            <Clientsslider />
        </section>

        <div className="py-2"></div>

        <section className="pt-5 pb-5">
            <div className="container-lg cl-custome">
                <div className="common-section">

                    <div className="common-heading-bx">
                        <div className="common-heading">FAQs: Your Guide to <span className="ch-color">Seamless Trading</span></div>
                        <div className="common-sub-heading">Unlock the answers you need with our FAQs. From account setup to trading strategies, find quick solutions to common queries, ensuring your trading journey with WinproFX is smooth and successful.</div>
                    </div>

                    <div id="faqsaccordion" className="faqs-accordion  px-0 mt-4"> {/*  data-bs-parent="#faqsaccordion" */}
                    {questions.map((item, index) => (
                    <div data-aos="fade-up" className="acc-item" key={index}>
                        <div className="acc-heding collapsed" id={`heading${index}`} data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                            {item.question}
                            <span className="down-icon di-two">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6`}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                            </svg> */}
                            </span>
                        </div>

                        <div id={`collapse${index}`} className="collapse" aria-labelledby={`heading${index}`}>
                            <div className="faqs-data">
                                {item.answer}
                            </div>
                        </div>
                    </div>
                    ))}
                    </div>

                </div>
            </div>
        </section>

        <div className="py-2"></div>

        <section className="pt-5 pb-5">
            <div className="container-lg cl-custome">
                <div className="textlayout-main-bx py-0">
                    <div data-aos="fade-up" className="tlm-heading heading-24">Embark on a transformative trading journey with us — <br/> where trading isn't just an action, it's a difference. Trade <br/> with us, be the difference.</div>
                    <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">Dive into a world where every trade creates a ripple of impact. Join us in shaping a unique trading experience that not only sets you apart but also makes a positive difference. Trade with purpose, trade with the Best Online Trading Platform.</div>
                    <div data-aos="fade-up" data-aos-delay="500" className="tlm-btns">
                        {/* <div data-bs-target="#SignInBtns" data-bs-toggle="modal" className="tlm-btn-items tbi-fill"><span>Start your Journey today</span></div> */}
                        <a href="https://my.winprofx.com/en/login" target='_blank' rel="noreferrer" className="tlm-btn-items tbi-fill"><span>Start your Journey today</span></a>
                        {/* <div onClick={() => handleScreenChange('register-screen')} className="tlm-btn-items tbi-fill"><span>Start your Journey today</span></div> */}
                    </div> 
                </div>
            </div>
        </section>
    </>
  );
};
