import React from "react";
import { Link } from "react-router-dom";
// import { Helmet } from "react-helmet-async";
import CommonHelmet from '../../components/CommonHelmet';


export default function PageTermsAndConditions() {
  return (
    <>

      {/*  <Helmet>
        <title>Terms & Conditions | WinproFX</title>
      </Helmet> */}
      <CommonHelmet title="Terms & Conditions | WinproFX" />

      <section className="relative">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx pb-3">
            <div className="breadcrumb-bx">
                <Link className="breadcrumb-link" to="/">Home</Link>
                <div className="breadcrumb-link breadcrumb-active">/ Terms & Conditions</div>
            </div>
            <div className="tlm-heading">Terms & Conditions</div>
          </div>
        </div>
      </section>

      <section className="pb-5">
          <div className="container-lg cl-custome legal-content">

            <div className="main-sub-lable">1. Acceptance of Terms</div>
            <p>1.1 By accessing or using any services or products offered by WinproFX.com (hereinafter referred to as "WinproFX"), you agree to be bound by the terms and conditions outlined in this Trading Platform Agreement. If you do not agree with any part of these terms, please refrain from accessing or using WinproFX services and products.</p>
            <p>1.2 This Trading Platform Agreement applies to all users and customers of WinproFX, including both guests and registered users. By accessing or using our platform, you signify your acceptance of this agreement.</p>

            <div className="main-sub-lable">2. Binding Agreement</div>
            <p>2.1 By agreeing to this Trading Platform Agreement, you are entering into a legally binding contract with WinproFX.</p>
            <p>2.2 You acknowledge and agree that your use of WinproFX services and products constitutes acceptance of this Trading Platform Agreement and any other applicable WinproFX contractual documentation, including but not limited to the General Terms and Conditions.</p>

            <div className="main-sub-lable">3. Age Requirement</div>
            <p>3.1 You must be at least eighteen (18) years old or the legal age for entering into legally binding contracts under applicable laws to use WinproFX services and products. By continuing to access or use our platform, you affirm that you meet this age requirement.</p>
            <p>3.2 Misrepresentation of your age to gain access to WinproFX services and products is strictly prohibited and constitutes a breach of this Trading Platform Agreement.</p>
  
            <div className="main-sub-lable">4. Termination of Agreement</div>
            <p>4.1 WinproFX reserves the right to terminate this Trading Platform Agreement and deny access to its services and products to any user who violates the terms outlined herein.</p>
            <p>4.2 In the event of termination, you must cease all use of WinproFX services and products immediately.</p>
  
            <div className="main-sub-lable">5. Modifications to Agreement</div>
            <p>5.1 WinproFX reserves the right to modify or update this Trading Platform Agreement at any time without prior notice. It is your responsibility to review this agreement periodically for changes.</p>
            <p>5.2 Your continued use of WinproFX services and products after any modifications to this Agreement constitutes acceptance of those changes.</p>
  

            <div className="main-sub-lable">6. Contact Information</div>
            <p>6.1 If you have any questions or concerns about this Trading Platform Agreement or WinproFX services and products, please contact us at: <a href="mailto:support@winprofx.com" target="_blank" rel="noreferrer">support@winprofx.com</a></p>
  
          </div>
      </section>

    </>
  );
};
