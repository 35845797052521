import React from 'react';
import {Link} from "react-router-dom";
import CommonHelmet from '../../components/CommonHelmet';

// Design Files
// import { useMenu } from '../../components/Header/MenuContext'
import Commonsection from '../Commonsection';

// Images Common File
import * as Img from '../../components/Img';

import wave1 from '../../img/bgimg/wave(1).svg'
import wave2 from '../../img/bgimg/wave(2).svg'
import wave3 from '../../img/bgimg/wave(3).svg'

import PlayStore from '../../img/icons/PlayStore.png';
import laptop from '../../img/icons/laptop.svg';
import mobile from '../../img/icons/mobile.svg';

import laptopfull from '../../img/laptopfull.png';
import mobilefull from '../../img/mobilefull.png';

import winprofx from '../../img/winprofx.exe';

// import laptopComputer from '../../img/icons/laptop-computer.png';



export default function PagePlatform() {

  // const { handleScreenChange } = useMenu();
  
  return (
    <>
      <CommonHelmet 
        title="Best Forex & CFD Trading Platform Online Brokers For Stock Trading"
        description="The top Forex Trading Platform on MT5 for Android and iPhone. Unlock endless trading opportunities download MT5 now and start your success journey."
        keywords="Forex Trading Platform, WinProFX Trading Platform, Best Forex Platform, MT5 Trading Platform, Forex Trading Tools, CFD Trading Platform, Commodities Trading Platform, Online Trading Solutions, Advanced Trading Platform, Forex Market Tools, Best Forex Trading Broker Platform, Best Forex Market Trading Platform, Best Online Trading Platform For Professional Traders In India, Best Forex Market Trading Platforms For Advanced Traders, Best Forex Trading Platform For India Beginners, Best Trading Brokers For Beginners, Best Crypto Currency Trading Platform, Best Free Funded Forex Account Without Deposit, Forex Broker 0 Spread, Free Funded Account, Free Funded Accounts, Best Forex Trading Platform"
      />
      <div className="d-none" style={{display:"none"}}>
        <h1>Best Forex & CFD Trading Platform Online Brokers For Stock Trading</h1>
        <h2>The top Forex Trading Platform on MT5 for Android and iPhone. Unlock endless trading opportunities download MT5 now and start your success journey.</h2>
        <b>Forex Trading Platform</b>
        <b>WinProFX Trading Platform,</b>
        <b>Best Forex Platform,</b>
        <b>MT5 Trading Platform</b>
        <b>Forex Trading Tools</b>
        <b>CFD Trading Platform</b>
        <b>Commodities Trading</b>
        <b>Commodities Trading Platform</b>
        <b>Online Trading Solutions</b>
        <b>Advanced Trading Platform</b>
        <img src="" alt='Forex Trading Platform, WinProFX Trading Platform, Best Forex Platform, MT5 Trading Platform, Forex Trading Tools, CFD Trading Platform, Commodities Trading Platform, Online Trading Solutions, Advanced Trading Platform, Forex Market Tools, Best Forex Trading Broker Platform, Best Forex Market Trading Platform, Best Online Trading Platform For Professional Traders In India, Best Forex Market Trading Platforms For Advanced Traders, Best Forex Trading Platform For India Beginners, Best Trading Brokers For Beginners, Best Crypto Currency Trading Platform, Best Free Funded Forex Account Without Deposit, Forex Broker 0 Spread, Free Funded Account, Free Funded Accounts, Best Forex Trading Platform' />
      </div>

      <section className="relative">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx">
            <div className="breadcrumb-bx">
                <Link className="breadcrumb-link" to="/">Home</Link>
                <div className="breadcrumb-link breadcrumb-active">/ Platform</div>
            </div>
            <div data-aos="fade-up" className="tlm-heading">MT5 Unleashed: Dive into <br  /> Limitless <span className="tlm-h-color">Trading Success</span> with <br className="d-none d-sm-block"/> WinproFX</div>
            <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">WinproFX has achieved significant success in global business expansion. We offer the Best Forex Trading Platform on MT5, accessible on both Android and iPhone, bringing you one step closer to success. Take the plunge into a realm of endless trading opportunities by downloading MT5 now.</div>
            <div data-aos="fade-up" data-aos-delay="500" className="tlm-btns">
                {/* <div data-bs-target="#SignUpBtns" data-bs-toggle="modal" className="tlm-btn-items tbi-fill"><span>Open Account</span></div> */}
                <a href="https://my.winprofx.com/en/register" target='_blank' rel="noreferrer" className="tlm-btn-items tbi-fill"><span>Open Account</span></a>
                {/* <div onClick={() => handleScreenChange('register-screen')} className="tlm-btn-items tbi-fill"><span>Open Account</span></div> */}
                {/* <div className="tlm-btn-items tbi-none">Try Demo <img src={Img.arrowright} alt="" /> </div> */}
            </div>
          </div>
        </div>
        <div className="textlayout-bg-bx aefiuhgaevse">
          <img src={wave1} alt="" />
          <img src={wave2} alt="" />
          <img src={wave3} alt="" />
        </div> 
      </section>

      
      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="gradient-section gradient2 pt-0 ps-0">
            
            <div className="row">       

              <div className="col-md-6">
                <img data-aos="fade-right" className="d-none d-md-block" style={{width: "100%"}} src={mobile} alt="" />
                <img data-aos="fade-up" className="d-block d-md-none" style={{width: "100%"}} src={mobilefull} alt="" />
              </div>

              <div className="col-md-6 ps-4 mt-5 mb-5">
                <div className="common-heading-bx text-end">
                  <div data-aos="fade-up" className="small-lable">TRADING PLATFORM</div>
                  <div data-aos="fade-up" className="common-heading">Download MT 5 <br/> <span className="ch-color">For Mobile</span></div>
                  <div data-aos="fade-up" className="common-sub-heading mt-4">WinproFX has remained highly successful to expand their business all over the world, and now we are MT 5 is now available on Android and iPhone, and you are one step closer to success. Download MT 5 now and dive into the world of limitless trading opportunities.</div>
                </div>
                
                <div data-aos="fade-up" className="playstores-btn align-items-center align-items-md-end mt-4">
               {/* href="https://play.google.com/store/apps/details?id=net.metaquotes.metatrader5&hl=en&referrer=ref_id%3d5188947219764832733%26utm_source%3dwww.metatrader5.com%26utm_campaign%3dinstall.metaquotes" */}
                  <a target="_blank" rel="noreferrer" href="https://winprofx.com/assets/images/metatrader5.apk" className="playstores-item">
                    <img src={PlayStore} alt="" />
                    <div className="playstores-data">
                      <div className="psd-lable">GET IT FOR</div>
                      <div className="psd-heading">Google Play</div>
                    </div>
                  </a>

                  <a target="_blank" rel="noreferrer" href='https://apps.apple.com/us/app/metatrader-5-forex-stocks/id413251709?utm_campaign=install.metaquotes&utm_source=www.metatrader5.com' className="playstores-item">
                    <img src={Img.macOS} alt="" />
                    <div className="playstores-data">
                      <div className="psd-lable">GET IT FOR</div>
                      <div className="psd-heading">App Store</div>
                    </div>
                  </a>

                </div>

              </div>

            </div>

          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5 overflowhidden">
        <div className="container-lg cl-custome">
          <div className="gradient-section gradient2 pe-md-0">
            
            <div className="row mt-5">       

              <div className="col-md-6 mb-5 order-2 order-md-1">
                <div className="common-heading-bx">
                  <div data-aos="fade-up" className="small-lable">TRADING PLATFORM</div>
                  <div data-aos="fade-up" className="common-heading">Download MT 5 <br/> <span className="ch-color">For Web</span></div>
                  <div data-aos="fade-up" className="common-sub-heading mt-4">WinproFX has remained highly successful to expand their business all over the world, and now we are MT 5 is now available on Android and iPhone, and you are one step closer to success. Download MT 5 now and dive into the world of limitless trading opportunities.</div>
                </div>
                
                <div data-aos="fade-up" className="playstores-btn align-items-center align-items-md-start mt-4">

                  <a download={true} href={winprofx} className="playstores-item">
                    <img src={Img.windows} alt="" />
                    <div className="playstores-data">
                      <div className="psd-lable">GET IT FOR</div>
                      <div className="psd-heading">Windows</div>
                    </div>
                  </a>

                  {/* <a download={true} href={winprofx} className="playstores-item">
                    <img src={Img.macOS} alt="" />
                    <div className="playstores-data">
                      <div className="psd-lable">GET IT FOR</div>
                      <div className="psd-heading">mac OS</div>
                    </div>
                  </a> */}

                  <div className="playstores-item" style={{filter:"grayscale(1) opacity(0.7)", pointerEvents:"none"}}>
                    <img src={Img.macOS} alt="" />
                    <div className="playstores-data">
                      <div className="psd-lable">Coming Soon</div>
                      <div className="psd-heading">mac OS</div>
                    </div>
                  </div>

                </div>

              </div>

              <div className="col-md-6 mb-5 order-1 order-md-2">
                <img data-aos="fade-left" className="d-none d-md-block" style={{width: "100%"}} src={laptop} alt="" />
                <img data-aos="fade-up" className="d-block d-md-none" style={{width: "100%"}} src={laptopfull} alt="" />
              </div>
           

            </div>

          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="common-section">
            <Commonsection />
          </div>
        </div>
      </section>

      <div className="py-2"></div>

      <section className="pt-5 pb-5">
        <div className="container-lg cl-custome">
          <div className="textlayout-main-bx py-0">
            <div data-aos="fade-up" className="tlm-heading heading-24">Embark on Mastery: Unleash Your Skills with the <br/> WinproFX <span className="tlm-h-color">Demo Experience!</span></div>
            <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">"Embark on your trading journey with a complimentary 21-day MT5 demo account. Unveil the power of our award-winning Best Forex Trading Strategies Platform risk-free – register now and explore the art of precision trading."</div>
            <div data-aos="fade-up" data-aos-delay="500" className="tlm-btns">
                {/* <div data-bs-target="#SignUpBtns" data-bs-toggle="modal" className="tlm-btn-items tbi-fill"><span>MT5 Platform Demo</span></div> */}
                <a href="https://my.winprofx.com/en/register" target='_blank' rel="noreferrer" className="tlm-btn-items tbi-fill"><span>MT5 Platform Demo</span></a>
                {/* <div onClick={() => handleScreenChange('register-screen')} className="tlm-btn-items tbi-fill"><span>MT5 Platform Demo</span></div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
