import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import { Helmet } from 'react-helmet-async';
import CommonHelmet from '../../components/CommonHelmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import * as Img from '../../components/Img';

import EmptyBox from "../../components/EmptyBox"

export default function PageAbout() {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out',
            duration: 800,
        });

        // Fetch blogs from API
        const fetchBlogs = async () => {
            setLoading(true);
            try {
                const response = await fetch('https://backend.winprofx.com/api/blogs'); // Replace with your API URL
                const data = await response.json();
                setBlogs(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);

    return (
        <>
            {/*  <Helmet>
                <title>News & Insights | WinproFx</title>
            </Helmet> */}
            <CommonHelmet title="News & Insights | WinproFx" />

            <section className="relative">
                <div className="container-lg cl-custome">
                    <div data-aos="fade-up" className="textlayout-main-bx">
                        <div className="breadcrumb-bx">
                            <Link className="breadcrumb-link" to="/">Home</Link>
                            <div className="breadcrumb-link breadcrumb-active">/ News & Insights</div>
                        </div>
                        <div className="tlm-heading">News & <span className="tlm-h-color">Insights</span></div>
                        <div className="tlm-sub-text">Stay informed with our latest market updates and expert insights, empowering you to <br /> make well-informed decisions in the world of finance.</div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg cl-custome">
                    <div className="common-section">
                        <div className="row news-card-row mt-4">
                            {blogs.length > 0 && !loading && (
                                blogs.map((blog, index) => (
                                    <div key={index} data-aos="fade-up" className="col-lg-3 col-sm-6 mb-4">
                                        <a href={blog.link} rel="noreferrer" target="_blank" className="news-card-item">
                                            <div className="nci-tag">{blog.company}</div>
                                            <div className="nci-heading">{blog.title}</div>
                                            <div className="nci-read-time">1 minute to read</div>
                                            <div className="nci-text">{blog.description}</div>
                                            <div className="nci-readmore">Read<img src={Img.arrowright} alt="" /></div>
                                            <div className="nci-date">{new Date(blog.created_at).toLocaleDateString()}</div>
                                        </a>
                                    </div>
                                ))
                            )}
                            
                            {blogs && blogs?.length === 0 && !loading && (
                                <EmptyBox 
                                    className="empty-div-bx" 
                                    eh="No news & insights" 
                                    // esh="Data is not available"
                                /> 
                            )}

                            {loading && (
                                <>
                                <div data-aos="fade-up" className="col-lg-3 col-sm-6 mb-4">
                                    <div className="skeletant-bx skeletant-design sh-280"></div>
                                </div>

                                <div data-aos="fade-up" className="col-lg-3 col-sm-6 mb-4">
                                    <div className="skeletant-bx skeletant-design sh-280"></div>
                                </div>

                                <div data-aos="fade-up" className="col-lg-3 col-sm-6 mb-4">
                                    <div className="skeletant-bx skeletant-design sh-280"></div>
                                </div>

                                <div data-aos="fade-up" className="col-lg-3 col-sm-6 mb-4">
                                    <div className="skeletant-bx skeletant-design sh-280"></div>
                                </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            <div className="py-2"></div>
        </>
    );
}
