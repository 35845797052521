import React from "react";
import MyRouter from "./routers/index";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>

      <MyRouter />

    </HelmetProvider>
  );
}

export default App;





