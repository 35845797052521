import React, { useState, useEffect }  from 'react';
import {Link, NavLink, useLocation} from "react-router-dom";

// Css Files
import '../../css/header.css';

// Images Common File
import * as Img from '../Img';

// Design File
import { useMenu } from './MenuContext';
// import Topstrip from './Topstrip';
import Sidemenu from './Sidemenu';
// import Signinup from './Signinup';
import DropdownMenu from './DropdownMenu';
// import GoogleTranslate from '../GoogleTranslate';

export default function Header({hideSection}) {

    // --- Header Js Start --- //
    const [headerSticky, setheaderSticky] = useState("");
    const [isHeaderActive, setIsHeaderActive] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 10) {
          setheaderSticky("active");
        } else {
          setheaderSticky("");
        }

        const timepassElement = document.getElementById('timepass');

        if (timepassElement) {
            const rect = timepassElement.getBoundingClientRect();
            const topOffset = rect.top;

            if (topOffset <= 0) {
              setIsHeaderActive(true);
              document.body.classList.add('active-dark');
            } else {
              setIsHeaderActive(false);
              document.body.classList.remove('active-dark');
            }
        }

      };
  
      // Attach the event listener when the component mounts
      window.addEventListener('scroll', handleScroll);
  
      // Remove the event listener when the component unmounts to avoid memory leaks
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []); // Empty dependency array ensures the effect runs only once when the component mounts
  
    // --- Header Js End --- //


    // --- ---
    // const [isDropActive, setIsDropActive] = useState(false);

    // const handleMouseEnter = () => {
    //   setIsDropActive(true);
    // };

    // const handleMouseLeave = () => {
    //   setIsDropActive(false);
    // };

    // const handleItemClick = () => {
    //   setIsDropActive(false); // Remove active class when an item is clicked
    // };
    // --- ---

    // --- ---
    // const [isGTDropActive, setIsGTDropActive] = useState(false);

    // const handleGTOpenDrop = () => {
    //   setIsGTDropActive(true);
    // }
    
    // const handleGTMouseEnter = () => {
    //   setIsGTDropActive(true);
    // };

    // const handleGTMouseLeave = () => {
    //   setIsGTDropActive(false);
    // };

    // const handleGTCloseDrop = () => {
    //   setIsGTDropActive(false);
    // }
    // --- ---

    const marketsLinks = [
      { to: '/forex', text: 'Forex', imgSrc: Img.arrowright },
      { to: '/indices', text: 'Indices', imgSrc: Img.arrowright },
      { to: '/crypto', text: 'Crypto', imgSrc: Img.arrowright },
      { to: '/metals', text: 'Metals', imgSrc: Img.arrowright },
      { to: '/commodities', text: 'Commodities', imgSrc: Img.arrowright },
      { to: '/oil', text: 'Oil', imgSrc: Img.arrowright },
    ];

    // const toolsLinks = [
    //   { to: '/analytical-tools', text: 'Analytical tools', imgSrc: Img.arrowright },
    //   { to: '/economic-calendar', text: 'Economic calendar', imgSrc: Img.arrowright },
    //   { to: '/currency-calculator', text: 'Currency calculator', imgSrc: Img.arrowright },
    //   { to: '/currency-converter', text: 'Currency converter', imgSrc: Img.arrowright },
    // ];

    const { toggleMenu } = useMenu();
    // const { handleScreenChange } = useMenu();

    // -----
    const location = useLocation();

    let weblogo = "";

    if (location.pathname === '/introducing-broker' && isHeaderActive) {
      weblogo = "";
    } else if (location.pathname === '/introducing-broker'){
      weblogo = "active";
    }
    // -----

    return (
      <>
        {/* <Topstrip /> */}
        
        <header className={`maxwidth1920 custom-header ${headerSticky}`}>
          <div className="ch-inner">

            <div className="ch-left">
              {/* Website Logo Start */}
              {!hideSection ? (
                <div className="ch-logo">
                  <img src={weblogo ?  Img.logo_dark : Img.logo_light} alt="" />
                  <img className={` ${weblogo ?  "" : "lighticon"} `} src={weblogo ? Img.icon_dark : Img.icon_light} alt="" />
                </div>
                ):(
                <Link to="/" className="ch-logo">
                  <img src={weblogo ?  Img.logo_dark : Img.logo_light} alt="" />
                  <img className={` ${weblogo ?  "" : "lighticon"} `} src={weblogo ? Img.icon_dark : Img.icon_light} alt="" />
                </Link>
                )}
              {/* Website Logo End */}
            </div>

            {/* Website Menu Start */}
            {hideSection && 
            <div className="ch-menu-bx">

              <DropdownMenu title="Markets" links={marketsLinks} />

              <NavLink to="/platform" className="cmb-item">
                Platform
                <div className="cmb-item-line"></div>
              </NavLink>

              <NavLink to="/about" className="cmb-item">
                About Us
                <div className="cmb-item-line"></div>
              </NavLink>


              {/* <NavLink to="/introducing-broker" className="cmb-item">
                Introducing Broker
                <div className="cmb-item-line"></div>
                </NavLink>
                
                <NavLink to="/contact" className="cmb-item">
                Contact Us
                <div className="cmb-item-line"></div>
                </NavLink> */}

              <NavLink to="/education" className="cmb-item">
                Education
                <div className="cmb-item-line"></div>
              </NavLink>

              <NavLink to="/news" className="cmb-item">
                News & Insights
                <div className="cmb-item-line"></div>
              </NavLink>

              {/* <DropdownMenu cdiclassName="cdi-tools-width" title="Tools" links={toolsLinks} /> */}

              {/* <NavLink to="/introducing-broker" className="cmb-item cmb-item-highlight">
                Introducing Broker
                <div className="cmb-item-line"></div>
              </NavLink> */}

            </div>
            }
            {/* Website Menu End */}

            <div className="ch-right">

            {hideSection && 
              <div className="ch-menu-bx px-0">
                <NavLink to="/introducing-broker" className="mx-0 cmb-item cmb-item-highlight">
                  Introducing Broker
                  <div className="cmb-item-line"></div>
                </NavLink>
              </div>
            }



              {/* Signup Btns Start */}
              <div className="signinup-btn-bx">
                {/* <div data-bs-target="#SignInBtns" data-bs-toggle="modal" className="sip-btn">Login</div> */}
                {/* <div data-bs-target="#SignUpBtns" data-bs-toggle="modal" className="sip-btn register-sip-btn ms-2" sbdata="Register"><span className='sbdata'>Register</span><span>Register</span></div> */}
                {hideSection &&
                  <a href="https://my.winprofx.com/en/login" target='_blank' rel="noreferrer" className="sip-btn">Login</a>
                }
                <a href="https://my.winprofx.com/en/register" target='_blank' rel="noreferrer" className="sip-btn register-sip-btn ms-2" sbdata="Register"><span className='sbdata'>Register</span><span>Register</span></a>
              </div>
              {/* Signup Btns ENd */}

              {/* --- GoogleTranslate Start --- */}
              {/*<div className={`GT-main-bx notranslate  ${isGTDropActive ? 'active' : ''}`} onMouseEnter={handleGTMouseEnter} onMouseLeave={handleGTMouseLeave}>
                <div className="GT-icon-bx" onClick={handleGTOpenDrop}>
                  <img src={Img.translate} alt="" />
                </div>
                <div className={`GT-drop-bx`}>
                  <div className="GT-drop-inner">
                    <div className="GT-di-lable">Language</div>
                    <span className="close-icon" onClick={handleGTCloseDrop}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                        </svg>
                    </span>
                    <GoogleTranslate onOptionSelect={handleGTCloseDrop} />
                  </div>
                </div>
              </div>*/}
              {/* --- GoogleTranslate End --- */}

              {hideSection && 
                <div className="menu-icon-bx" onClick={toggleMenu}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              }

            </div>
            
          </div>
        </header>

        {/* <Signinup /> */}
        <Sidemenu />
      </>
    );
}
  